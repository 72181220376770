import React from 'react';
import classNames from 'classnames';

import ModalWrapper from 'components/ModalWrapper';
import { CardLink } from 'components/CardLink';
import { getCoverImageUrl } from 'components/ModalMedia/utils';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { PropertyImageSizes } from 'constants/propertyImageSizes';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { IProperty } from 'interfaces/IProperty';
import { IMarket, IMarketWithProperties } from 'interfaces/IMarket';
import locations from 'routes';
import { formatStringWithDot } from 'utils/formatters/string';
import { translateText } from 'utils/i18n';
import { getPropertyAddress } from 'utils/formatters/property';

import styles from './ModalMarketsLinks.module.scss';
import { getMarketWithProperties } from '../utils';

interface Props {
  isVisible: boolean;
  markets: IMarket[];
  onClose: () => void;
  properties: IProperty[];
}

const ModalMarketsLinks: React.FC<Props> = ({
  isVisible,
  markets,
  properties,
  onClose,
}) => {
  if (!isVisible) return null;

  const marketsWithProperties = getMarketWithProperties(markets, properties);

  const getLinkRow = (market: IMarketWithProperties) => {
    return (
      <>
        <CardLink
          key={market.id}
          link={locations.showMarket(market?.id!)}
          title={market.name}
          subtitle={undefined}
          tertiaryTitle={formatStringWithDot([market.country?.name])}
        />
        {!!market.properties.length && (
          <div className={styles['market-properties']}>
            {market.properties.map(p => (
              <CardLink
                key={p.id}
                imageSrc={getCoverImageUrl(p, PropertyImageSizes.l)}
                link={locations.showProperty(p?.id!)}
                title={getPropertyAddress(p)!}
                subtitle={p.name || DASH_PLACEHOLDER}
                tertiaryTitle={formatStringWithDot([
                  p.market?.name,
                  p.submarket?.name,
                  p.micromarket?.name,
                ])}
              />
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <ModalWrapper
      onCancel={onClose}
      wrapperClassName={classNames(
        styles['modal-container'],
        styles['links-container'],
      )}
      header={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.market_plural`)}
      withCloseButton
      noPadding
      large
    >
      <section className={styles.content}>
        {marketsWithProperties.map(getLinkRow)}
      </section>
    </ModalWrapper>
  );
};

export default ModalMarketsLinks;
