import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { camelCase } from 'lodash';
import { useApolloClient, useQuery } from '@apollo/client';
import AddressSearchInput from 'components/AddressSearchInput';
import FormControl from 'components/FormControl';
import { BusinessParkSearchInput } from 'components/BusinessparkSearchInput';
import RemoteChoiceInput from 'components/Inputs/RemoteChoiceInput';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import LatLonInput from 'components/Inputs/LatLonInput';
import LatLonMap from 'components/LatLonMap';
import Button from 'components/Button';
import TextInput from 'components/Inputs/TextInput';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { DataCenterBuildType } from 'constants/dataCenter';
import { REGEX_INTEGERS } from 'constants/regex';
import {
  UnitOfMeasurementCode,
  UNITS_OF_MEASUREMENT_CODE_MAPPING,
} from 'constants/unitOfMeasurement';
import { CountryCodes } from 'constants/countryCodes';
import { CURRENCY_CODES_BY_COUNTRY } from 'constants/currencies';
import { LocationTypeIds } from 'constants/locationTypes';
import { PropertyTypeNames, PropertyTypes } from 'constants/propertyTypes';
import {
  GET_PROPERTY_MARKET_DATA,
  GET_PROPERTY_QUERY,
  PROPERTY_TENANCY_TYPES,
} from 'graphql/property/queries';
import {
  PROPERTY_CLASSES_QUERY,
  PROPERTY_LOCATION_TYPES_QUERY,
  PROPERTY_STATUSES_QUERY,
  PROPERTY_TYPES_ENTITIES_QUERY,
} from 'graphql/property';
import { WHERE_AM_I } from 'graphql/geospatial';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import { geospatialClient } from 'graphql/geospatialClient';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  WhereAmIResponse,
  WhereAmIResponsePayload,
} from 'interfaces/IWhereAmI';
import { IdName } from 'interfaces/IdName';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { ErrorLogger } from 'services/ErrorLogger';
import { translateText } from 'utils/i18n';
import { getDateISOString } from 'utils/date';
import { getCurrencySymbol } from 'utils/formatters/currency';
import styles from '../../FormSection.module.scss';
import {
  getAddressParts,
  getCountryCodeByAddress,
} from '../../../../AddressSearchInput/getAddressParts';
import { useSubtypeDetails } from '../usePropertyForm';
import { FieldType } from '../types';
import { CONSTRUCTION_TYPE_OPTIONS } from '../utils';
import { DATA_CENTER_BUILD_TYPE_OPTIONS } from '../BuildingFields/constants';
import SelectProperty from '../../FormFields/SelectProperty';
import { ConvertedPropertyDto } from '../useConvertedProperty';

interface Props {
  primaryAddress: React.MutableRefObject<HTMLDivElement | null>;
  primaryAddressButton: React.MutableRefObject<HTMLDivElement | null>;
  secondaryAddressContainer: React.MutableRefObject<HTMLDivElement | null>;
  secondaryAddress: React.MutableRefObject<HTMLDivElement | null>;
  secondaryAddressButton: React.MutableRefObject<HTMLDivElement | null>;
  tertiaryAddressContainer: React.MutableRefObject<HTMLDivElement | null>;
  tertiaryAddress: React.MutableRefObject<HTMLDivElement | null>;
  tertiaryAddressButton: React.MutableRefObject<HTMLDivElement | null>;
  quaternaryAddressContainer: React.MutableRefObject<HTMLDivElement | null>;
  setAddressCountry: (addressCountry?: string | null) => void;
  setProperty: (property: IPropertyInput) => void;
  updateField: (fieldName: string, fieldValue: unknown) => void;
  updateBuildingField: (fieldName: string, fieldValue: any) => void;
  property?: IPropertyInput;
  propertySubtypes: IdName[];
  handleErrorMessage?: (message: string) => void;
  loadSubtypesByType: (typeId?: number) => void;
  convertedFromProperty?: ConvertedPropertyDto;
  setConvertedFromProperty?: Dispatch<SetStateAction<ConvertedPropertyDto>>;
}
export const GeneralFields = ({
  primaryAddress,
  primaryAddressButton,
  secondaryAddressContainer,
  secondaryAddress,
  secondaryAddressButton,
  tertiaryAddressContainer,
  tertiaryAddress,
  tertiaryAddressButton,
  quaternaryAddressContainer,
  property,
  propertySubtypes,
  updateField,
  updateBuildingField,
  handleErrorMessage,
  setAddressCountry,
  setProperty,
  loadSubtypesByType,
  convertedFromProperty,
  setConvertedFromProperty,
}: Props) => {
  const client = useApolloClient();

  const { data: dataSubtypeDetails } = useSubtypeDetails(
    property?.propertySubtype,
  );
  const {
    data: convertedProperty,
    loading: loadingConvertedProperty,
  } = useQuery(GET_PROPERTY_QUERY, {
    variables: { id: convertedFromProperty?.id },
    skip: !convertedFromProperty?.id,
  });

  const isPropertyDataCenterType = property?.propertyType?.id === 5;
  const isConvertedType = property?.status?.id === 8;

  const isRetrofitSelected = useMemo(
    () =>
      property?.dataCenter?.dataCenterBuildOutType?.name ===
      DataCenterBuildType.RETROFIT,
    [property?.dataCenter?.dataCenterBuildOutType?.name],
  );

  const propertySubtypeDetails = dataSubtypeDetails
    ? dataSubtypeDetails?.propertySubtypeDetails?.results
    : [];
  if (!property) return <></>;

  const updateLocationType = async (latitude?: number, longitude?: number) => {
    const { data } = await geospatialClient.query<
      WhereAmIResponse,
      WhereAmIResponsePayload
    >({
      query: WHERE_AM_I,
      fetchPolicy: 'cache-first',
      variables: {
        latitude: latitude!,
        longitude: longitude!,
        geoHierarchyLevels: ['adm1'],
      },
    });

    const locationType = data.whereAmI
      .filter(w => !!w.locationType)
      .map(w => w.locationType!)
      .shift();

    if (locationType) {
      updateField('locationType', {
        id: LocationTypeIds[camelCase(locationType)],
      });
    }
  };

  const onSubtypeChange = (subtype: IdName | undefined) => {
    updateField('propertySubtypeDetail', null);
    updateField('propertySubtype', subtype);
  };

  const updatePropertyMarketData = async (
    latitude?: number,
    longitude?: number,
    propertyTypeId?: number,
  ) => {
    if (!latitude || !longitude) return;

    const { data, errors } = await client.query({
      query: GET_PROPERTY_MARKET_DATA,
      variables: {
        latitude,
        longitude,
        propertyTypeId,
      },
    });

    if (data.marketHierarchy?.market) {
      updateField('market', data.marketHierarchy?.market);
      updateField('submarket', data.marketHierarchy?.submarket);
      updateField('micromarket', data.marketHierarchy?.micromarket);
    } else {
      updateField('market', undefined);
      updateField('submarket', undefined);
      updateField('micromarket', undefined);
      handleErrorMessage?.(
        translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.marketNotFound`),
      );
    }

    if (errors) {
      errors.map(e => ErrorLogger.log(e.message));
      console.error('Unable to load the property markets data', errors);
      return;
    }
  };

  const removePrimaryAddressButton = () => {
    primaryAddressButton.current!.style.display = 'none';
    secondaryAddressContainer.current!.style.display = 'block';
    secondaryAddress.current!.style.width = '78%';
    secondaryAddressButton.current!.style.display = 'block';
    primaryAddress.current!.style.width = '100%';
  };

  const removeSecondaryAddressButton = () => {
    secondaryAddressButton.current!.style.display = 'none';
    secondaryAddress.current!.style.width = '100%';
    tertiaryAddressContainer.current!.style.display = 'block';
    tertiaryAddress.current!.style.width = '78%';
    tertiaryAddressButton.current!.style.display = 'block';
  };

  const removeTertiaryAddressButton = () => {
    tertiaryAddressButton.current!.style.display = 'none';
    tertiaryAddress.current!.style.width = '100%';
    quaternaryAddressContainer.current!.style.display = 'block';
  };

  const clearBuildingFieldsAndPersist = (fieldToPersist: FieldType) => {
    Object.keys(FieldType).forEach(field => {
      if (field === fieldToPersist || field === FieldType.common) return;
      updateField(field, undefined);
    });
  };
  const onTypeChange = (type: IdName | undefined) => {
    const typePropertyName = PropertyTypeNames[type?.id];
    loadSubtypesByType(type?.id);
    if (type?.id !== property.propertyType?.id) {
      updateField('propertySubtype', undefined);
      updateField('propertySubtypeDetail', undefined);
    }
    updateField('propertyType', type);

    // When switching property type, clear other building fields
    switch (typePropertyName) {
      case PropertyTypes.office: {
        clearBuildingFieldsAndPersist(FieldType.office);
        break;
      }
      case PropertyTypes.flexIndustrial: {
        clearBuildingFieldsAndPersist(FieldType.industrial);
        break;
      }
      case PropertyTypes.industrial: {
        clearBuildingFieldsAndPersist(FieldType.industrial);
        break;
      }
      case PropertyTypes.dataCenter: {
        clearBuildingFieldsAndPersist(FieldType.dataCenter);
        break;
      }
      case PropertyTypes.multifamily: {
        clearBuildingFieldsAndPersist(FieldType.multifamily);
        break;
      }
      case PropertyTypes.healthcare: {
        clearBuildingFieldsAndPersist(FieldType.healthcare);
        break;
      }
    }

    updatePropertyMarketData(
      property.latitudeDisplay || property.latitude,
      property.longitudeDisplay || property.longitude,
      type?.id,
    );
  };

  const onSelectAddressSuggestion = (
    place: google.maps.places.PlaceResult | null,
    suggestionDescription?: string,
  ) => {
    const addressParts = getAddressParts(place, suggestionDescription);
    const latitude = place?.geometry?.location?.lat();
    const longitude = place?.geometry?.location?.lng();
    const countryCode = getCountryCodeByAddress(place);

    setAddressCountry(addressParts?.country);
    if (countryCode === CountryCodes.GB) {
      primaryAddressButton.current!.style.display = 'block';
      primaryAddress.current!.style.width = '78%';
    }

    updateField('state', addressParts?.state);
    updateField('city', addressParts?.city || '');
    updateField('primaryAddress', addressParts?.primaryAddress);
    updateField('displayAddress', addressParts?.primaryAddress);
    updateField('postalCode', addressParts?.postalCode);
    updateField('latitude', latitude);
    updateField('longitude', longitude);
    updateField('latitudeDisplay', latitude);
    updateField('longitudeDisplay', longitude);
    updateField(
      'currencyCode',
      CURRENCY_CODES_BY_COUNTRY[countryCode || CURRENCY_CODES_BY_COUNTRY.US],
    );
    updateField(
      'measurementSystem',
      UNITS_OF_MEASUREMENT_CODE_MAPPING[countryCode || CountryCodes.US],
    );
    updateLocationType(latitude, longitude);
    updatePropertyMarketData(latitude, longitude);
  };

  const resetAddressInputs = () => {
    primaryAddress.current!.style.width = '100%';
    primaryAddressButton.current!.style.display = 'none';
    secondaryAddressContainer.current!.style.display = 'none';
    tertiaryAddressContainer.current!.style.display = 'none';
    quaternaryAddressContainer.current!.style.display = 'none';
    updateField('secondaryAddress', '');
    updateField('tertiaryAddress', '');
    updateField('quaternaryAddress', '');
  };

  return (
    <>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.primaryAddress',
        )}
        wrapperClassName={styles['form-row']}
        required
      >
        <div className={styles['form-address-button-container']}>
          <div
            className={styles['flexible-address-input']}
            ref={primaryAddress}
          >
            <AddressSearchInput
              clearable
              value={property.primaryAddress || ''}
              onClear={() => {
                updateField('displayAddress', '');
                updateField('locationType', undefined);
                updateField('currencyCode', getCurrencySymbol());
                updateField('measurementSystem', UnitOfMeasurementCode.US);
                resetAddressInputs();
              }}
              onChange={(value: string) => {
                updateField('primaryAddress', value);
                updateField('state', undefined);
                updateField('city', undefined);
                updateField('latitude', undefined);
                updateField('longitude', undefined);
                updateField('locationType', undefined);
                if (value === '') {
                  resetAddressInputs();
                }
              }}
              onClickSuggestion={onSelectAddressSuggestion}
            />
          </div>
          <div
            className={styles['form-address-button']}
            ref={primaryAddressButton}
          >
            <Button
              wrapperClassName={styles['button']}
              onClick={removePrimaryAddressButton}
              type={'ghost'}
              label={translateText(
                'avantPlatform.attributes.property.label.addAlternativeAddress',
              )}
              size={'small'}
            />
          </div>
        </div>
      </FormControl>
      <div
        ref={secondaryAddressContainer}
        className={styles['hidden-container']}
      >
        <FormControl
          label={translateText(
            'avantPlatform.attributes.property.label.secondaryAddress',
          )}
          wrapperClassName={styles['form-row']}
        >
          <div className={styles['form-address-button-container']}>
            <div
              className={styles['flexible-address-input']}
              ref={secondaryAddress}
            >
              <TextInput
                fullWidth
                borderBox
                value={property.secondaryAddress || ''}
                placeholder={translateText(
                  `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterAddress`,
                )}
                onChange={(value: string) => {
                  updateField('secondaryAddress', value);
                }}
              />
            </div>
            <div
              className={styles['form-address-button']}
              ref={secondaryAddressButton}
            >
              <Button
                wrapperClassName={styles['button']}
                onClick={removeSecondaryAddressButton}
                type={'ghost'}
                label={translateText(
                  `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.addAlternativeAddress`,
                )}
                size={'small'}
              />
            </div>
          </div>
        </FormControl>
      </div>
      <div
        ref={tertiaryAddressContainer}
        className={styles['hidden-container']}
      >
        <FormControl
          label={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.tertiaryAddress`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <div className={styles['form-address-button-container']}>
            <div
              className={styles['flexible-address-input']}
              ref={tertiaryAddress}
            >
              <TextInput
                fullWidth
                borderBox
                value={property.tertiaryAddress || ''}
                placeholder={translateText(
                  `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterAddress`,
                )}
                onChange={(value: string) => {
                  updateField('tertiaryAddress', value);
                }}
              />
            </div>
            <div
              className={styles['form-address-button']}
              ref={tertiaryAddressButton}
            >
              <Button
                wrapperClassName={styles['button']}
                onClick={removeTertiaryAddressButton}
                type={'ghost'}
                label={translateText(
                  `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.addAlternativeAddress`,
                )}
                size={'small'}
              />
            </div>
          </div>
        </FormControl>
      </div>
      <div
        className={styles['hidden-container']}
        ref={quaternaryAddressContainer}
      >
        <FormControl
          label={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.quaternaryAddress`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <TextInput
            fullWidth
            borderBox
            value={property.quaternaryAddress || ''}
            placeholder={translateText(
              `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterAddress`,
            )}
            onChange={(value: string) => {
              updateField('quaternaryAddress', value);
            }}
          />
        </FormControl>
      </div>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.displayAddress`,
        )}
        wrapperClassName={styles['form-row']}
        disabled={
          !['primaryAddress', 'city', 'state'].every(field => !!property[field])
        }
      >
        <TextInput
          fullWidth
          borderBox
          value={property.displayAddress}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterDisplayAddress',
          )}
          onChange={value => updateField('displayAddress', value)}
          isDisabled={
            !['primaryAddress', 'city', 'state'].every(
              field => !!property[field],
            )
          }
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.buildingName`,
        )}
        wrapperClassName={styles['form-row']}
      >
        <TextInput
          fullWidth
          borderBox
          value={property.name}
          placeholder={translateText(
            'avantPlatform.attributes.property.prompt.enterBuildingName',
          )}
          onChange={value => updateField('name', value)}
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.businessParkName`,
        )}
        wrapperClassName={styles['form-row']}
      >
        <BusinessParkSearchInput
          initialValue={property?.businessPark?.name}
          updateFn={updateField}
          marketId={property.market?.id}
        />
      </FormControl>
      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.type`)}
        wrapperClassName={styles['form-row']}
        required
        isMultiline
      >
        <RemoteChoiceInput
          query={PROPERTY_TYPES_ENTITIES_QUERY}
          queryParser={data => data?.propertyTypesEntities || []}
          selectedItem={property.propertyType}
          containerWrapperClassName={styles['radio-input-container-multiline']}
          onChange={onTypeChange}
        />
      </FormControl>
      {!!propertySubtypes.length && (
        <FormControl
          label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.subtype`)}
          wrapperClassName={styles['form-row']}
          required
        >
          <ChoiceInput
            selectedItem={property.propertySubtype}
            data={propertySubtypes}
            containerWrapperClassName={
              styles['radio-input-container-multiline']
            }
            onChange={onSubtypeChange}
          />
        </FormControl>
      )}
      {!!propertySubtypeDetails.length && !!property.propertySubtype?.id && (
        <FormControl
          label={translateText(
            `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.subtypeDetail`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <ChoiceInput
            selectedItem={property.propertySubtypeDetail}
            data={propertySubtypeDetails}
            containerWrapperClassName={
              styles['radio-input-container-multiline']
            }
            onChange={details => updateField('propertySubtypeDetail', details)}
          />
        </FormControl>
      )}
      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`)}
        isMultiline
        required
        wrapperClassName={styles['form-row']}
      >
        <TextInput
          fullWidth
          borderBox
          value={property.market?.name}
          isDisabled
        />
      </FormControl>
      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.submarket`)}
        wrapperClassName={styles['form-row']}
        disabled={!property.market?.id}
        isMultiline
      >
        <TextInput
          fullWidth
          borderBox
          value={property.submarket?.name}
          isDisabled
        />
      </FormControl>
      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.micromarket`)}
        wrapperClassName={styles['form-row']}
        isMultiline
        disabled={!property.submarket?.id}
      >
        <TextInput
          fullWidth
          borderBox
          value={property.micromarket?.name}
          isDisabled
        />
      </FormControl>
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.latitudeLongitude`,
        )}
        wrapperClassName={styles['form-row']}
      >
        <LatLonInput
          lat={property.latitudeDisplay || property.latitude}
          lon={property.longitudeDisplay || property.longitude}
        />
      </FormControl>
      <LatLonMap
        latLng={{
          lat: property.latitudeDisplay || property.latitude,
          lng: property.longitudeDisplay || property.longitude,
        }}
        marketId={property.market?.id}
        propertyTypeId={property.propertyType?.id}
        onMapChange={coordinate => {
          setProperty((currentProperty: IPropertyInput) => ({
            ...currentProperty,
            latitudeDisplay: coordinate.lat,
            longitudeDisplay: coordinate.lng,
          }));
          updatePropertyMarketData(
            coordinate.lat,
            coordinate.lng,
            property.propertyType?.id,
          );
        }}
      />
      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.locationType`,
        )}
        wrapperClassName={styles['form-row']}
        isMultiline
      >
        <RemoteChoiceInput
          isDisabled
          query={PROPERTY_LOCATION_TYPES_QUERY}
          queryParser={data => data?.locationTypes || []}
          selectedItem={property.locationType}
          containerWrapperClassName={styles['radio-input-container-multiline']}
          onChange={item => updateField('locationType', item)}
        />
      </FormControl>
      <FormControl
        label={translateText('avantPlatform.attributes.property.label.status')}
        required
        wrapperClassName={styles['form-row']}
        isMultiline
      >
        <RemoteChoiceInput
          query={PROPERTY_STATUSES_QUERY}
          queryParser={data => data?.propertyStatuses || []}
          selectedItem={property.status}
          containerWrapperClassName={styles['radio-input-container-multiline']}
          onChange={item => {
            updateField('status', item);
            if (item?.id === 8) {
              updateField('demolishedConverted', getDateISOString());
            }
          }}
        />
      </FormControl>
      {isConvertedType && (
        <SelectProperty
          label={translateText(
            'avantPlatform.attributes.property.label.convertedTo',
          )}
          id="converted-property"
          value={{
            id: convertedProperty?.property?.id,
            primaryAddress: convertedProperty?.property?.primaryAddress,
            name: convertedProperty?.property?.name,
          }}
          graphqlParams={{
            query: SEARCH_COMPANIES_PROPERTIES_QUERY,
            field: 'name',
            resultDataName: 'propertiesCompanies',
            onlyProperties: true,
          }}
          parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
            return data.map(propertyCompany => ({
              ...propertyCompany,
              name: propertyCompany.displayAddress || propertyCompany.name,
            }));
          }}
          isDisabled={loadingConvertedProperty}
          onChange={(value: IPropertyCompany) => {
            setConvertedFromProperty?.({
              id: value.propertyId,
              isDeleted: false,
            });
          }}
          onClear={() => {
            setConvertedFromProperty?.(prev => ({
              ...prev,
              isDeleted: true,
            }));
          }}
        />
      )}
      {isPropertyDataCenterType && (
        <>
          <FormControl
            label={translateText(
              `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.dataCenterBuildType`,
            )}
            wrapperClassName={styles['form-row']}
          >
            <ChoiceInput
              data={DATA_CENTER_BUILD_TYPE_OPTIONS}
              itemWrapperClassName={styles['form-choice-input']}
              selectedItem={property?.dataCenter?.dataCenterBuildOutType}
              labelFieldName="id"
              onChange={value =>
                updateBuildingField('dataCenterBuildOutType', value)
              }
            />
          </FormControl>
          {isRetrofitSelected && (
            <FormControl
              label={translateText(
                `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.yearRetrofitted`,
              )}
              wrapperClassName={styles['form-row']}
            >
              <TextInput
                pattern={REGEX_INTEGERS}
                value={property?.dataCenter?.retrofitYear ?? null}
                placeholder={translateText(
                  'avantPlatform.attributes.property.prompt.enterYear',
                )}
                onChange={value => {
                  if (!value) updateBuildingField('retrofitYear', null);
                  updateBuildingField('retrofitYear', Number(value));
                }}
              />
            </FormControl>
          )}
        </>
      )}

      <FormControl
        label={translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.developmentType`,
        )}
        wrapperClassName={styles['form-row']}
      >
        <ChoiceInput
          selectedItem={{
            name: property.constructionType?.name,
          }}
          data={CONSTRUCTION_TYPE_OPTIONS}
          onChange={item => {
            updateField('constructionType', item);
          }}
        />
      </FormControl>
      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.class`)}
        wrapperClassName={styles['form-row']}
      >
        <RemoteChoiceInput
          query={PROPERTY_CLASSES_QUERY}
          queryParser={data => data?.propertyClasses || []}
          selectedItem={property.propertyClass}
          containerWrapperClassName={styles['radio-input-container']}
          onChange={item => updateField('propertyClass', item)}
        />
      </FormControl>
      <FormControl
        label={translateText(
          'avantPlatform.attributes.property.label.tenancyType',
        )}
        wrapperClassName={styles['form-row']}
        isMultiline
      >
        <RemoteChoiceInput
          query={PROPERTY_TENANCY_TYPES}
          queryParser={data => data?.tenancyTypes || []}
          selectedItem={property.tenancyType}
          containerWrapperClassName={styles['radio-input-container-multiline']}
          onChange={tenancyType => updateField('tenancyType', tenancyType)}
        />
      </FormControl>
    </>
  );
};
