import React from 'react';
import classnames from 'classnames';
import styles from './Checkbox.module.scss';

type CheckboxProps = {
  label: string;
  selected: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  labelClassName?: string;
};

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { label, selected, disabled, onChange, labelClassName } = props;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label className={styles.container}>
      <input
        type="checkbox"
        className={styles.checkbox}
        checked={selected}
        disabled={disabled}
        onChange={onChangeHandler}
      />
      <span className={styles.checkmark}></span>
      <div
        className={classnames(
          styles.label,
          { [styles.selected]: selected },
          labelClassName,
        )}
      >
        {label}
      </div>
    </label>
  );
};

export default Checkbox;
