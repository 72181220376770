import { History } from 'history';
import { t } from 'i18next';

import avantCitiesRoutes from 'avantCitiesRoutes';
import { RECORD_TYPE_OPTIONS } from 'components/CreateComps/FormSection/LeaseForm/staticData';
import { LEASE_EXPIRING_TIME_OPTIONS } from 'components/FindComps/EditSearchCriteria/StaticFilters/leaseTime';
import { goToFindCompsResult } from 'components/NavigationButtonGroup/utils';
import BUILDING_DATE_OPTIONS from 'components/FindComps/EditSearchCriteria/StaticFilters/buildingDate';
import SALE_DATE_OPTIONS from 'components/FindComps/EditSearchCriteria/StaticFilters/saleDate';
import { FindCompTabs, FindCompTabsViews } from 'constants/FindCompTabs';
import { StatusTypes, StatusTypesIDs } from 'constants/statusTypes';
import { SortDirections } from 'constants/sortDirections';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_ENUMERATORS,
  I18N_AVANT_PROPERTY_PAGES,
  I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { LEASE_COMP_STATUSES } from 'constants/compStatuses';
import { IMarket } from 'interfaces/IMarket';
import { IUser } from 'interfaces/IUser';
import { IDynamicURL } from 'interfaces/IDynamicURL';
import { IPropertyType } from 'interfaces/IPropertyType';
import { IPropertySubType } from 'interfaces/IPropertySubType';
import { IPropertyClasses } from 'interfaces/IPropertyClasses';
import locations from 'routes';
import { arrayToMap } from 'utils/objects';
import { translateText } from 'utils/i18n';

export interface IMenuLink {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface IMenuDropdownItem {
  title: string;
  links: IMenuLink[];
  classname?: string;
  lineSeparator?: boolean;
}

//@TODO - Remove this variable when we are getting the correct data from DB
export const marketAnalyticsNames: { [key: number]: string } = {
  0: '',
  1: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.development`),
  2: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.leasing`),
  3: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.sale_plural`),
  5: t(`${I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH}.largeBlocks`),
  6: t(`${I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH}.historicalStats`),
  7: t(`${I18N_AVANT_PROPERTY_ENUMERATORS}.propertyTypes.dataCenter`),
  8: t(`${I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH}.dataCenterLand`),
  9: t(`${I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH}.flexOfficeSupply`),
  10: t(`${I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH}.ventureCapitalFunding`),
  11: t(`${I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH}.marketVitalityIndex`),
  12: t(`${I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH}.fortune500`),
  25: t(`${I18N_AVANT_PROPERTY_PAGES_HOME_LABEL_PATH}.netLease`),
};

export const getMarketAnalyticsLinks = (
  history: History,
  dashboards?: IDynamicURL[],
  ids?: number[],
  fullAccess?: boolean,
): IMenuDropdownItem[] => {
  if (!dashboards) return [];

  let dashboardsWithAccess: IDynamicURL[] = [];
  if (fullAccess) {
    dashboardsWithAccess = dashboards.filter(x => x.id !== 4);
  } else {
    const dashboardMaps: any = arrayToMap(ids || []);
    dashboardsWithAccess = dashboards.filter(
      x => x.id !== 4 && dashboardMaps[x.id!],
    );
  }

  if (dashboardsWithAccess.length === 0) return [];

  const firstItem = {
    label: dashboardsWithAccess[0].name || '',
    onClick: () =>
      history.push(
        locations.marketAnalytics({
          dashboardId: dashboardsWithAccess[0].id,
        }),
      ),
  };

  const grouppedDashboards = dashboardsWithAccess.reduce(
    (prev, current, index) => {
      if (index === 0) return prev;
      const temp = prev;
      const currentObject = {
        label: current.name || '',
        onClick: () => {
          if (history.location.pathname === '/market-analytics') {
            history.push(
              locations.marketAnalytics({
                dashboardId: current.id,
              }),
            );
            window.location.reload();
          } else {
            history.push(
              locations.marketAnalytics({
                dashboardId: current.id,
              }),
            );
          }
        },
      };
      if (temp[current.group]) {
        temp[current.group].push(currentObject);
      } else {
        temp[current.group] = [currentObject];
      }
      return temp;
    },
    { [dashboardsWithAccess[0].group]: [firstItem] },
  );

  const groups = Object.keys(grouppedDashboards).map(key => ({
    title: key,
    classname: 'menu-group',
    links: grouppedDashboards[key],
  }));

  return groups;
};

interface IPropertyOptions {
  label: string;
  onClick: () => void;
}

export const getCompPropertiesLinks = (
  user: IUser,
  userMarkets: IMarket[],
  userPreferredType: IPropertyType | null,
  userPreferredSubTypes: IPropertySubType[] | null,
  propertyClasses: IPropertyClasses[],
  history: History,
): IMenuDropdownItem[] => {
  const propertyOptions: IPropertyOptions[] = [];

  if (propertyClasses) {
    propertyClasses?.forEach(propClass => {
      const newPropertyOption = {
        label: propClass.name,
        onClick: () =>
          goToFindCompsResult(
            user,
            history,
            userMarkets,
            FindCompTabs.properties,
            {
              types: [userPreferredType ?? {}],
              classes: [propClass],
            },
          ),
      };

      propertyOptions.push(newPropertyOption);
    });
  }

  userPreferredSubTypes?.forEach(subType => {
    if (!subType.name) return;
    const newPropertyOption = {
      label: subType.name,
      onClick: () =>
        goToFindCompsResult(
          user,
          history,
          userMarkets,
          FindCompTabs.properties,
          {
            types: [userPreferredType ?? {}],
            subtypes: [subType],
          },
        ),
    };

    propertyOptions.push(newPropertyOption);
  });

  propertyOptions.push({
    label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.all`),
    onClick: () =>
      goToFindCompsResult(
        user,
        history,
        userMarkets,
        FindCompTabs.properties,
        {},
        {},
        FindCompTabsViews.list,
      ),
  });

  const propertyLinks = [
    {
      classname: 'leases-comps',
      title: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`),
      links: [
        {
          label: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.leaseComp_plural`,
          ),
          onClick: () => {
            const compStatuses = LEASE_COMP_STATUSES.map(c => ({
              id: c.id,
            }));

            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.leases,
              {},
              { confidential: false, compStatuses },
              FindCompTabsViews.list,
            );
          },
        },
        {
          label: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.leaseExpirations`,
          ),
          onClick: () =>
            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.leases,
              {},
              {
                expirationTime: LEASE_EXPIRING_TIME_OPTIONS[2],
                recordType: RECORD_TYPE_OPTIONS[1],
                confidential: false,
              },
              FindCompTabsViews.list,
            ),
        },
        {
          label: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.tenantsInMarket`,
          ),
          onClick: () =>
            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.tims,
              {},
              {},
              FindCompTabsViews.list,
            ),
        },
      ],
    },
    {
      classname: 'properties-comps',
      title: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`),
      links: propertyOptions,
      lineSeparator: true,
    },
    {
      classname: 'development-comps',
      title: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.development`),
      links: [
        {
          label: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.recentDeliveries`,
          ),
          onClick: () =>
            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.properties,
              {
                builtYears: [
                  {
                    id: BUILDING_DATE_OPTIONS[0].id,
                    name: BUILDING_DATE_OPTIONS[0].name,
                  },
                ],
              },
              {},
              FindCompTabsViews.list,
            ),
        },
        {
          label: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.underDevelopment`,
          ),
          onClick: () =>
            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.properties,
              {
                statuses: [
                  {
                    id: StatusTypesIDs.UnderConstruction,
                    name: StatusTypes.UnderConstruction,
                  },
                  {
                    id: StatusTypesIDs.UnderRenovation,
                    name: StatusTypes.UnderRenovation,
                  },
                ],
              },
              {},
              FindCompTabsViews.list,
            ),
        },
        {
          label: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.proposedProperties`,
          ),
          onClick: () =>
            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.properties,
              {
                statuses: [
                  {
                    id: StatusTypesIDs.ProposedShortTerm,
                    name: StatusTypes.ProposedShortTerm,
                  },
                  {
                    id: StatusTypesIDs.ProposedLongTerm,
                    name: StatusTypes.ProposedLongTerm,
                  },
                ],
              },
              {},
              FindCompTabsViews.list,
            ),
        },
        {
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.all`),
          onClick: () =>
            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.properties,
              {},
              {},
              FindCompTabsViews.list,
            ),
        },
      ],
      lineSeparator: true,
    },
    {
      classname: 'sales-comps',
      title: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.sale_plural`),
      links: [
        {
          label: translateText(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.recentSales`,
          ),
          onClick: () =>
            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.sales,
              {},
              {},
              FindCompTabsViews.list,
              {
                date: {
                  id: SALE_DATE_OPTIONS[2].id,
                  name: SALE_DATE_OPTIONS[2].name,
                },
              },
            ),
        },
        {
          label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.all`),
          onClick: () =>
            goToFindCompsResult(
              user,
              history,
              userMarkets,
              FindCompTabs.sales,
              {},
              {},
              FindCompTabsViews.list,
            ),
        },
      ],
    },
  ];

  return propertyLinks;
};

export const getSubMarketVariables = (
  propertyTypeIds: number[],
  market?: IMarket,
  orderFieldName = 'name',
  sort = SortDirections.asc,
) => {
  if (!market) return {};

  return {
    search: {
      filter: {
        markets: [{ id: market.id }],
        propertyTypeIds,
      },
      order: { field: orderFieldName, direction: sort },
    },
  };
};

export const getTopFiveMarketList = (listMarket?: IMarket[]) => {
  const orderedByPropertyCount = listMarket?.sort((a, b) => {
    return (b?.propertiesCount || 0) - (a.propertiesCount || 0);
  });

  return orderedByPropertyCount && orderedByPropertyCount.length > 5
    ? orderedByPropertyCount.slice(0, 5)
    : orderedByPropertyCount;
};

export const getMappingLinks = (): IMenuDropdownItem[] => [
  {
    classname: '',
    title: translateText(`avantPlatform.common.words.cities`),
    links: [
      {
        label: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.marketExplorer`,
        ),
        onClick: () =>
          window.open(`${avantCitiesRoutes.root()}/explore`, '_blank'),
      },
      {
        label: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.mapMaker`,
        ),
        onClick: () => window.open(avantCitiesRoutes.mapMaker(), '_blank'),
      },
      {
        label: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.marketSelection`,
        ),
        onClick: () =>
          window.open(`${avantCitiesRoutes.root()}/marketselection`, '_blank'),
      },
      {
        label: translateText(
          `${I18N_AVANT_PROPERTY_PAGES}.home.labels.siteSelection`,
        ),
        onClick: () => window.open(avantCitiesRoutes.siteSelection(), '_blank'),
      },
    ],
  },
  {
    classname: '',
    title: translateText(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.products`),
    links: [
      {
        label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.panoramaTitle`),
        onClick: () => window.open(avantCitiesRoutes.panorama(), '_blank'),
      },
      {
        label: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.officeTitle`),
        onClick: () => window.open(avantCitiesRoutes.office(), '_blank'),
      },
      {
        label: translateText(
          `${I18N_PLATFORM_COMMON_WORD_PATH}.healthcareTitle`,
        ),
        onClick: () => window.open(avantCitiesRoutes.healthcare(), '_blank'),
      },
      {
        label: translateText(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.parcelFinder`,
        ),
        onClick: () => window.open(avantCitiesRoutes.parcelFinder(), '_blank'),
      },
    ],
  },
];
