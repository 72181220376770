import React from 'react';
import AYLink from 'components/AYLink';
import styles from './CardLink.module.scss';

interface Props {
  title: string;
  subtitle: string | undefined;
  tertiaryTitle: string;
  link: string;
  imageSrc?: string;
}
export const CardLink = ({
  title,
  subtitle,
  tertiaryTitle,
  link,
  imageSrc,
}: Props) => {
  return (
    <div className={styles['card-link']}>
      {!!imageSrc && (
        <img alt={title} className={styles.image} src={imageSrc} />
      )}
      <div>
        <AYLink wrapperClassName={styles.title} to={link}>
          {title}
        </AYLink>
        <h2 className={styles.subtitle}>{subtitle}</h2>
        {tertiaryTitle && (
          <h3 className={styles['tertiary-title']}>{tertiaryTitle}</h3>
        )}
      </div>
    </div>
  );
};
