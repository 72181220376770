import React, { useEffect, useContext, ComponentProps } from 'react';
import { ExportTypes } from 'constants/exportTypes';
import { SortDirections } from 'constants/sortDirections';
import {
  I18N_AVANT_PROPERTY_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ISaleSearch } from 'interfaces/ISalesSearch';
import { ILeaseSearch } from 'interfaces/ILeasesSearch';
import { IPropertySearch } from 'interfaces/IPropertySearch';
import { ICompsetSearch } from 'interfaces/inputs/ICompsetSearch';
import { removeTypenameKey } from 'utils/graphql/typename';
import { NotificationContext } from 'contexts/NotificationContext';
import { stringifyGraphQL } from 'utils/formatters/string';
import { translateText } from 'utils/i18n';
import { useFileDownload } from 'hooks/useFileDownload';

import Button from '../Button/new';

type Props = ComponentProps<typeof Button> & {
  exportType?: ExportTypes;
  exportSubtype?: string;
  queryVariables?:
    | ISaleSearch
    | ILeaseSearch
    | IPropertySearch
    | ICompsetSearch;
  notificationClassName?: string;
  shouldParseFilter?: boolean;
};

const I18N_DOWNLOAD_BUTTON_TEXT_PATH = `${I18N_AVANT_PROPERTY_TEXT_PATH}.downloadButton`;

const DEFAULT_ERROR_MESSAGE = translateText(
  `${I18N_DOWNLOAD_BUTTON_TEXT_PATH}.downloadErrorTitle`,
);

const DownloadButton: React.FC<Props> = (props: Props) => {
  const {
    disabled,
    exportType,
    exportSubtype,
    label,
    queryVariables,
    wrapperClassName,
    iconPosition,
    notificationClassName,
    type = 'contextual-positive',
    shouldParseFilter = true,
    ...buttonProps
  } = props;

  const { setNotification } = useContext(NotificationContext);

  const defaultOrderBy =
    exportType === 'leases'
      ? {
          field: 'expirationDate',
          direction: SortDirections.asc,
        }
      : exportType === 'tims'
      ? {
          field: 'updatedAt',
          direction: SortDirections.desc,
        }
      : {
          field: 'id',
          direction: SortDirections.asc,
        };

  const getSearchVariables = () => {
    if (!queryVariables || !queryVariables.filter) {
      return null;
    }

    return {
      type: exportType,
      subType: exportSubtype,
      order: queryVariables.order
        ? stringifyGraphQL(removeTypenameKey(queryVariables.order))
        : stringifyGraphQL(removeTypenameKey(defaultOrderBy)),
      filters: shouldParseFilter
        ? stringifyGraphQL(removeTypenameKey(queryVariables.filter), true)
        : `${queryVariables.filter}`,
      createUpdate: queryVariables.createUpdate,
      shouldReturnPricingQuotes: true,
    };
  };

  const displayDownloadNotification = () =>
    setNotification({
      id: 'preparing-file-notification',
      title: translateText(
        `${I18N_DOWNLOAD_BUTTON_TEXT_PATH}.preparingFileTitle`,
      ),
      subtitle: translateText(
        `${I18N_DOWNLOAD_BUTTON_TEXT_PATH}.gatheringInfoMessage`,
      ),
      show: !isDownloading,
      wrapperClassName: notificationClassName,
    });

  const {
    isDownloading,
    hasError,
    setHasError,
    createFile,
    errorMessage,
  } = useFileDownload({
    onDownloadComplete: () => setNotification(undefined),
    queryVariables: getSearchVariables(),
  });

  const onClickHandler = async () => {
    displayDownloadNotification();
    await createFile();
  };

  useEffect(() => {
    if (hasError) {
      setNotification({
        title: errorMessage || DEFAULT_ERROR_MESSAGE,
        subtitle: translateText(
          `${I18N_DOWNLOAD_BUTTON_TEXT_PATH}.downloadErrorMessage`,
        ),
        show: hasError,
        wrapperClassName: notificationClassName,
        ...(exportType !== 'jsreport' && {
          onTryAgain: () => {
            setHasError(false);
            onClickHandler();
          },
        }),
      });
    }

    return () => {
      setHasError(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  return (
    <Button
      iconPosition={iconPosition || 'left'}
      description={
        !label
          ? translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.download`)
          : undefined
      }
      disabled={disabled || isDownloading}
      icon="download"
      iconSize={1.3}
      label={label}
      onClick={onClickHandler}
      type={type}
      wrapperClassName={wrapperClassName}
      {...buttonProps}
    />
  );
};

export default DownloadButton;
