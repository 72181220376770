import { useContext, useMemo } from 'react';
import { authContext } from 'contexts/AuthContext';
import { TIME_TOGGLE_OPTIONS } from 'constants/timeMeasurement';

export const useUserSettings = () => {
  const { user } = useContext(authContext);

  const preferredTimePeriod = useMemo(
    () =>
      TIME_TOGGLE_OPTIONS.find(
        t => t.value.toUpperCase() === user.preferences?.trackedPeriod,
      ),
    [user.preferences?.trackedPeriod],
  );

  return {
    preferredTimePeriod: preferredTimePeriod ?? TIME_TOGGLE_OPTIONS[1],
    isTrackedProperties: !!user.preferences?.includeInStats,
  };
};
