import React from 'react';

import FormControl from 'components/FormControl';
import TextInput from 'components/Inputs/TextInput';
import LoadingMessage from 'components/LoadingMessage';
import { translateText } from 'utils/i18n';

import styles from '../../FormSection.module.scss';
import localStyles from './ThirdPartyFields.module.scss';

interface Props {
  thirdPartyInfo?: any;
  isLoading: boolean;
  updateThirdPartyInfoValue: Function;
}

const ThirdPartyFields: React.FC<Props> = ({
  thirdPartyInfo,
  isLoading,
  updateThirdPartyInfoValue,
}) => {
  const renderThirdParty = (index: number, thirdParty: any) => {
    return (
      <FormControl
        key={index}
        label={thirdParty.thirdPartyTypeName}
        wrapperClassName={styles['form-row']}
      >
        <TextInput
          id={`thirdPartyId-${thirdParty.thirdPartyTypeName.toLowerCase()}`}
          value={thirdParty.value}
          placeholder={translateText(
            'avantPlatform.attributes.property.label.thirdPartyId',
          )}
          onChange={value => updateThirdPartyInfoValue(index, value)}
        />
      </FormControl>
    );
  };

  return isLoading ? (
    <LoadingMessage />
  ) : (
    <div className={localStyles.container}>
      {thirdPartyInfo?.map((thirdParty: any, index: number) =>
        renderThirdParty(index, thirdParty),
      )}
    </div>
  );
};

export default ThirdPartyFields;
