import { t } from 'i18next';
import { uniq } from 'lodash';

import { IScoop } from 'interfaces/IScoop';

import { IMarket, IMarketWithProperties } from 'interfaces/IMarket';
import { IProperty } from 'interfaces/IProperty';
import { DOT_LG } from 'constants/placeholders';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { deepCopy } from 'utils/objects';
import { pluralizeText } from 'utils/formatters/string';

const MAXIMUM_PROPERTY_TYPES_LENGTH = 2;
const MAXIMUM_COUNTRIES_LENGTH = 2;

export const getCountries = (scoop: IScoop) => {
  let result = '';
  if (!scoop?.markets) return result;
  const countries = uniq(scoop.markets.map(market => market.country?.name));
  const size = countries.length - 1;
  for (let i = 0; i <= size; i++) {
    if (i > MAXIMUM_COUNTRIES_LENGTH) break;
    result += ` ${countries[i]}`;
    if (i < size && i < MAXIMUM_PROPERTY_TYPES_LENGTH) {
      result += ` ${DOT_LG}`;
    }
  }
  return result;
};

export const getPropertyTypes = (scoop: IScoop) => {
  let result = '';
  if (!scoop?.propertyTypes) return result;
  const orderedPropertyTypes = deepCopy(scoop?.propertyTypes || []).sort(
    (a, b) => (a.id || 0) - (b.id || 0),
  );
  const size = orderedPropertyTypes.length - 1;
  for (let i = 0; i <= size; i++) {
    if (i > MAXIMUM_PROPERTY_TYPES_LENGTH) break;
    result += ` ${orderedPropertyTypes[i].name}`;
    if (i < size && i < MAXIMUM_PROPERTY_TYPES_LENGTH) {
      result += ` ${DOT_LG}`;
    }
  }
  return result;
};

export const getMultipleMarketsNamesShort = (
  markets: IMarket,
  preselectedMarket?: IMarket | undefined,
): string => {
  const marketName = preselectedMarket?.name || markets[0].name;
  if (markets.length === 1) return marketName || '';

  const totalOtherMarkets = markets.length - 1;
  const marketsLabel = pluralizeText(
    totalOtherMarkets,
    t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market`).toLowerCase(),
    t(`${I18N_PLATFORM_COMMON_WORD_PATH}.market_plural`).toLowerCase(),
  );
  return `${marketName} +${totalOtherMarkets} other ${marketsLabel}`;
};

export const getMarketWithProperties = (
  markets: IMarket[],
  properties: IProperty[],
): IMarketWithProperties[] => {
  return markets.map(m => {
    const propertiesByMarket =
      properties.filter(p => p.market?.id === m.id) ?? [];
    return {
      ...m,
      properties: propertiesByMarket,
    };
  });
};
