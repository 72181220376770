import React from 'react';
import classnames from 'classnames';
import styles from './ProfileSubSection.module.scss';
import Header from 'components/ProfileSubSection/Header';

interface Props {
  children: React.ReactNode | JSX.Element | JSX.Element[] | null;
  fontSizeHeader?: number;
  headerContent?: React.ReactNode;
  headerContentToRight?: boolean;
  isLastSubSection?: boolean;
  isSingleSection?: boolean;
  title?: string;
  titleColSize?: number;
  wrapperClassName?: string;
  rowWrapperClassName?: string;
}

const ProfileSubSection: React.FC<Props> = ({
  children,
  fontSizeHeader,
  headerContent,
  headerContentToRight = false,
  isLastSubSection,
  isSingleSection,
  title,
  titleColSize,
  wrapperClassName,
  rowWrapperClassName,
}) => {
  return (
    <div
      className={classnames(styles.container, wrapperClassName, {
        [styles['single-section']]: isSingleSection,
        [styles['last-sub-section']]: isLastSubSection,
      })}
    >
      <Header
        title={title}
        content={headerContent}
        contentToRight={headerContentToRight}
        fontSize={fontSizeHeader}
        titleColSize={titleColSize}
        rowWrapperClassName={classnames(rowWrapperClassName, styles.header)}
      />
      {children}
    </div>
  );
};

export default ProfileSubSection;
