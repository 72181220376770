import React, { forwardRef } from 'react';
import classNames from 'classnames';

interface PropTypes extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * You can pass a custom class to the `<Container>` element
   */
  wrapperClassName?: string;
  children?: React.ReactNode;
}

const Container = forwardRef<HTMLDivElement, PropTypes>((props, ref) => {
  const { wrapperClassName, children, ...attributes } = props;

  return (
    <div
      ref={ref}
      {...attributes}
      className={classNames('ay-container', wrapperClassName)}
    >
      {children}
    </div>
  );
});

export default Container;
