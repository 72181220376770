import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery, useApolloClient } from '@apollo/client';

import { Container } from 'components/@codelitt/ay-design-library';
import NotificationMessage from 'components/NotificationMessage';
import LoadingMessage from 'components/LoadingMessage';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_COMMON_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { CompType } from 'constants/compType';
import { ILease } from 'interfaces/ILease';
import { ITimInput } from 'interfaces/inputs/ITimInput';
import locations from 'routes';
import { ErrorLogger } from 'services/ErrorLogger';

import EditCompForm from '../EditCompForm';
import {
  DELETE_TIM_MUTATION,
  GET_TIM_QUERY,
  UPDATE_TIM_MUTATION,
} from 'graphql/tims';
import TenantInMarketForm from '../TenantInMarketForm';
import { buildTIMPayload } from '../TenantInMarketCreateForm/buildTIMPayload';
import { convertTimBeforeLoad } from '../converters';

interface Props {
  TIMId: string | undefined;
  onCancel?: () => void;
  onUpdated?: () => void;
  onDeleted?: () => void;
  title?: string;
  showDeleteButton?: boolean;
  redirectToShowCompsPage?: boolean;
}

const updatingErrorTIM = 'Unexpected error updating the TIM';

const TenantInMarketEditForm: React.FC<Props> = props => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const history = useHistory();

  const [tim, setTim] = useState<any>({});
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState(
    tim?.targetMarket?.country?.areaMeasurement,
  );
  const isSignedLeaseStatus = tim?.timStatusTypeId === 5;

  const { loading } = useQuery<ILease>(GET_TIM_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: props.TIMId },
    skip: !props.TIMId,
    onCompleted: data => {
      const measurement = data?.TIM?.targetMarket?.country?.areaMeasurement;
      const tim = convertTimBeforeLoad(data?.TIM);
      setTim(tim);
      setUnitOfMeasurement(measurement);
    },
  });

  const getRefetchLeaseQuery = () => {
    if (!props.redirectToShowCompsPage) {
      return [];
    }

    return [
      {
        query: GET_TIM_QUERY,
        variables: { id: props.TIMId },
      },
    ];
  };

  const updateTIM = async () => {
    try {
      setIsUpdating(true);
      const preparedTIM = buildTIMPayload(tim, unitOfMeasurement);

      const { data } = await client.mutate<{
        updateTIM: ITimInput;
      }>({
        mutation: UPDATE_TIM_MUTATION,
        variables: {
          TIM: preparedTIM,
        },
        refetchQueries: getRefetchLeaseQuery(),
      });

      if (data?.updateTIM?.id) {
        props.onUpdated?.();
        if (isSignedLeaseStatus) {
          history.push(
            locations.showComps(
              data?.updateTIM.id,
              CompType.tenantInMarket.toLowerCase(),
            ),
          );
        }
      } else {
        ErrorLogger.log(updatingErrorTIM);
        setMessageError(updatingErrorTIM);
        setShowError(true);
      }
    } catch (e) {
      ErrorLogger.log(e as any, updatingErrorTIM);
      setMessageError(updatingErrorTIM);
      setShowError(true);
    }
  };

  if (loading) {
    return (
      <Container>
        <LoadingMessage />
      </Container>
    );
  }

  return (
    <>
      <EditCompForm
        comp={tim}
        deleteErrorMessage={t(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.unableToDeleteTims`,
        )}
        deleteQueryParams={{
          query: DELETE_TIM_MUTATION,
          paramName: 'TIMId',
          resultName: 'deleteTIM',
        }}
        headerModal={t(
          `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.deleteTimComp`,
        )}
        paragraphModal={[
          `${t(
            `${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.aboutToDeleteTimComp`,
          )}.`,
          t(`${I18N_AVANT_PROPERTY_COMMON_TEXT_PATH}.askToProceed`),
        ]}
        onDeleted={props.onDeleted}
        showDeleteButton={props.showDeleteButton}
        title={props.title}
        compForm={
          <TenantInMarketForm
            tim={tim}
            setTim={setTim}
            onSubmit={updateTIM}
            setUnitOfMeasurement={setUnitOfMeasurement}
            unitOfMeasurement={unitOfMeasurement}
            onCancel={props.onCancel}
            submitLabel={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.update`)}
            isLoading={isUpdating}
            isUpdate={true}
          />
        }
      />

      <NotificationMessage
        show={showError}
        text={messageError}
        onClose={() => {
          setShowError(false);
        }}
      />
    </>
  );
};

export default TenantInMarketEditForm;
