import React, { useEffect } from 'react';
import styles from './CheckboxGroupInput.module.scss';
import CheckboxGroupInputItem from './CheckboxGroupInputItem';
import { IdName } from 'interfaces/IdName';
import classnames from 'classnames';
import SelectAndDeselect from 'components/SelectAndDeselect';
import { lowerCase, startCase } from 'lodash';
import Truncate from 'components/Truncate';

export type Size = 'regular' | 'big';

type PropTypes = {
  selectedItems: IdName[];
  data: IdName[];
  onChange: (items: IdName[]) => void;

  groupBy?: string;
  isDisabled?: boolean;
  isCustomKeyName?: boolean;
  isVertical?: boolean;
  isMultikey?: boolean;
  haveItemsBackground?: boolean;
  hideSelectAndDeselect?: boolean;

  topDropDownChildrenContainerClassName?: string;
  containerWrapperClassName?: string;
  multiKeyContainerWrapperClassName?: string;
  groupItemWrapperClassName?: string;
  itemWrapperClassName?: string;

  size?: Size;
  topDropdownChildren?: React.ReactNode;
  selectAllOption?: {
    value: string;
    isVisible: boolean;
    selectAllByDefault?: (allItems: IdName[]) => void;
  };
};

const CheckboxGroupInput: React.FC<PropTypes> = ({
  selectedItems,
  data,
  onChange,
  groupBy,
  isDisabled,
  isCustomKeyName,
  isVertical,
  isMultikey,
  haveItemsBackground,
  hideSelectAndDeselect,

  topDropDownChildrenContainerClassName,
  containerWrapperClassName,
  multiKeyContainerWrapperClassName,
  groupItemWrapperClassName,
  itemWrapperClassName,

  size,
  topDropdownChildren,
  selectAllOption,
}) => {
  const SELECT_ALL_OPTION = {
    id: 'all',
    name: selectAllOption?.value || 'all',
    type: {
      id: 'all_type',
      name: 'all',
    },
  };

  const dataWithAllOption = [SELECT_ALL_OPTION, ...(data || [])];
  const dataForRendering = selectAllOption?.isVisible
    ? dataWithAllOption
    : data;

  useEffect(() => {
    selectAllOption?.selectAllByDefault?.(dataForRendering);
    // eslint-disable-next-line
  }, []); // In order to only run the first time

  const getDataGroupedByKey = () =>
    dataForRendering.reduce((accumulator: any, currentValue: any) => {
      const key = currentValue[groupBy!]?.name || currentValue[groupBy!];

      if (!key) return null;

      if (!accumulator[key]) {
        accumulator[key] = [];
      }

      accumulator[key].push(currentValue);

      return accumulator;
    }, {});

  const getSelectionWithAllOption = (
    item: IdName,
    isSelected: boolean,
    standardSelectionList: IdName[],
  ) => {
    const listWithoutAllOption = standardSelectionList.filter(
      selection => selection.id !== SELECT_ALL_OPTION.id,
    );
    const selectAllList = isSelected ? dataWithAllOption : [];
    const isSelectAllActive = item.id === SELECT_ALL_OPTION.id;
    const isAllSelectedBySteps = listWithoutAllOption.length === data.length;
    const shouldReturnAllList =
      (!isSelectAllActive && isAllSelectedBySteps) || isSelectAllActive;

    return shouldReturnAllList ? selectAllList : listWithoutAllOption;
  };

  const getStandardSelectionList = (item: IdName, isSelected: boolean) =>
    isSelected
      ? [...selectedItems, { ...item }]
      : selectedItems.filter((el: IdName) => el.id !== item.id);

  const getSelection = (item: IdName, isSelected: boolean) => {
    const standardSelectionList = getStandardSelectionList(item, isSelected);
    return selectAllOption?.isVisible
      ? getSelectionWithAllOption(item, isSelected, standardSelectionList)
      : standardSelectionList;
  };

  const selectItems = (item: IdName, isSelected: boolean) =>
    onChange?.(getSelection(item, isSelected));

  if (!data?.length) return null;

  const itemsGroupedByKey = !!groupBy ? getDataGroupedByKey() : null;

  if (itemsGroupedByKey) {
    return (
      <div>
        {Object.keys(itemsGroupedByKey).map((key, index) => (
          <div key={key} className={styles['group-container']}>
            <div className={styles['group-key-container']}>
              {key !== SELECT_ALL_OPTION.type.name && (
                <p
                  className={styles['group-key-title']}
                  data-testid="group-key-title"
                >
                  {isCustomKeyName ? (
                    index == 0 ? (
                      <Truncate
                        length={15}
                        hasEllipsis={true}
                        wrapperClassName={styles.truncate}
                      >
                        {key}
                      </Truncate>
                    ) : (
                      key
                    )
                  ) : (
                    startCase(lowerCase(key))
                  )}
                </p>
              )}
              {!hideSelectAndDeselect && index === 0 && (
                <SelectAndDeselect
                  onSelectAll={items => onChange && onChange(items || [])}
                  onDeselectAll={() => onChange && onChange([])}
                  options={data}
                  selectedItems={selectedItems}
                  wrapperClassName={styles['select-and-deselect']}
                />
              )}
            </div>
            <div
              className={classnames(
                styles['checkbox-group-container'],
                containerWrapperClassName,
                groupItemWrapperClassName,
                {
                  [styles.vertical]: isVertical,
                },
              )}
              key={key}
            >
              {itemsGroupedByKey[key].map((item: IdName) => (
                <CheckboxGroupInputItem
                  key={item.id}
                  isDisabled={isDisabled}
                  isVertical={isVertical}
                  isSelected={selectedItems.some(
                    (el: IdName) => el.id === item.id,
                  )}
                  label={item.name!}
                  onClick={(isSelected: boolean) => {
                    selectItems(item, isSelected);
                  }}
                  size={size}
                  itemWrapperClassName={itemWrapperClassName}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  const renderSelectDeselect = () => {
    if (hideSelectAndDeselect) return;

    return (
      <div className={styles['group-key-container']}>
        <SelectAndDeselect
          onSelectAll={items => onChange && onChange(items || [])}
          onDeselectAll={() => onChange && onChange([])}
          options={data}
          selectedItems={selectedItems}
          wrapperClassName={styles['select-and-deselect']}
        />
      </div>
    );
  };

  return (
    <div className={styles['group-container']}>
      <div
        className={classnames(
          styles['checkbox-top-nodes'],
          topDropDownChildrenContainerClassName,
        )}
      >
        {topDropdownChildren}
        {renderSelectDeselect()}
      </div>
      <div
        className={classnames(
          styles['checkbox-group-container'],
          containerWrapperClassName,
          isMultikey
            ? multiKeyContainerWrapperClassName
            : containerWrapperClassName,
          {
            [styles.vertical]: isVertical,
          },
        )}
      >
        {dataForRendering.map((item: IdName) => (
          <CheckboxGroupInputItem
            key={item.id}
            isDisabled={isDisabled}
            isVertical={isVertical}
            hasBackground={haveItemsBackground}
            isSelected={selectedItems.some((el: IdName) =>
              el.id
                ? el.id === item.id
                : el.name?.toLowerCase() === item.name?.toLowerCase(),
            )}
            label={item.name!}
            onClick={(isSelected: boolean) => {
              selectItems({ id: item.id, name: item.name }, isSelected);
            }}
            size={size}
            itemWrapperClassName={itemWrapperClassName}
          />
        ))}
      </div>
    </div>
  );
};

export default CheckboxGroupInput;
