import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { t } from 'i18next';

import { Row, Col } from 'components/@codelitt/ay-design-library';
import { buildTagsListItems } from 'components/Leases/buildTagsListItems';
import { LeaseCardField } from 'components/Leases/fields';
import TagsList from 'components/TagsList';
import AYLink from 'components/AYLink';
import CompsComments from 'components/CompsComments';
import ListWithSeparator from 'components/ListWithSeparator';
import { ExpandingContracting } from 'constants/expandingContracting';
import { DOT_LG } from 'constants/placeholders';
import { authContext } from 'contexts/AuthContext';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH,
} from 'constants/i18n';
import { LanguageEnum } from 'constants/languages';
import { LEASE_OPTION_TYPES } from 'graphql/leases/queries';
import { ILease } from 'interfaces/ILease';
import { ICompany } from 'interfaces/ICompany';
import { IActivity } from 'interfaces/IActivity';
import { IdName } from 'interfaces/IdName';

import CardContainer from '../CardContainer';
import CardHeader from '../CardHeader';
import { TAG_LIMIT, TypeOfCard } from '../utils';
import locations from 'routes';
import Owner from '../Owner';
import { getLandlordBrokerCompany, getTenantBrokerCompany } from './utils';
import styles from './LeaseCard.module.scss';

export interface Props {
  className?: string;
  lease: ILease;
  activity?: IActivity;
  refetchScoopList?: () => void;
  type?: TypeOfCard;
  displayFields: LeaseCardField[];
  hideCreatedUpdate?: boolean;
  hideMarketLine?: boolean;
  hideBuildingAttributes?: boolean;
  hideBrokers?: boolean;
  hideBuildingInfo?: boolean;
}

const LeaseCard: React.FC<Props> = ({
  lease,
  type,
  activity,
  displayFields,
  refetchScoopList,
  hideCreatedUpdate,
  hideMarketLine,
  hideBuildingAttributes,
  hideBrokers,
  className,
  hideBuildingInfo,
}) => {
  const [isSeeMoreClicked, setIsSeeMoreClicked] = useState(false);

  const { data } = useQuery<{
    leaseOptionTypes: IdName[];
  }>(LEASE_OPTION_TYPES, {
    skip: !displayFields.some(f => f === LeaseCardField.LeaseOptions),
    fetchPolicy: 'cache-first',
  });

  const leaseOptionTypes = data?.leaseOptionTypes ?? [];

  const { user } = useContext(authContext);
  const property = lease.property;
  const tags = buildTagsListItems(lease, displayFields, leaseOptionTypes);
  if (user.language === LanguageEnum.EN_GB) {
    const tag = tags.find(tag => tag.id === 2);
    if (!!tag) {
      tag!.name = tag.name.replace('NNN', 'Headline');
    }
  }
  const isCarousel =
    type === TypeOfCard.CAROUSEL_PROPERTY || type === TypeOfCard.CAROUSEL;
  const tenantBrokerCompanyToRender = getTenantBrokerCompany(lease);
  const landlordBrokerCompanyToRender = getLandlordBrokerCompany(lease);
  const hasBroker =
    !!tenantBrokerCompanyToRender || !!landlordBrokerCompanyToRender;
  const isExpanding =
    displayFields.includes(LeaseCardField.ExpandingContracting) &&
    lease?.expandingContracting?.name === ExpandingContracting.EXPANDING;
  const isContracting =
    displayFields.includes(LeaseCardField.ExpandingContracting) &&
    lease?.expandingContracting?.name === ExpandingContracting.CONTRACTING;

  const renderSeparator = () => {
    if (!hideBuildingInfo) {
      if (!isCarousel) {
        return <div className={styles['card-separator']} />;
      }
    }
  };

  const renderTenantCompany = () => {
    return lease?.tenantCompany ? (
      <Row wrapperClassName={styles['tenant-company']}>
        <AYLink to={locations.showCompany(lease?.tenantCompany?.id!)}>
          <>
            {t(`${I18N_PLATFORM_ROLES_COMPANY_LABEL_PATH}.leasedBy`)}{' '}
            <b>{lease?.tenantCompany?.name}</b>
          </>
        </AYLink>
      </Row>
    ) : null;
  };

  const renderTags = () => {
    if (!tags.length) return null;

    return (
      <Row>
        <Col xs={4} lg={12}>
          <TagsList
            tags={tags}
            tagBackgroundClass={classNames(styles['lease-tag'], {
              [styles['share-lease-tag']]: type === TypeOfCard.SHARE,
              [styles['expanding-lease-tag']]: isExpanding,
              [styles['contracting-lease-tag']]: isContracting,
            })}
            tagLimit={isCarousel ? TAG_LIMIT : 0}
            onSeeMoreClick={() => setIsSeeMoreClicked(true)}
            forceShowMore={isCarousel && (!!lease.comments || hasBroker)}
          />
        </Col>
      </Row>
    );
  };

  const BrokerLink = ({
    company,
    label,
  }: {
    company?: ICompany;
    label: string;
  }) => {
    if (!company) return null;
    return (
      <AYLink to={locations.showCompany(company.id as number)}>
        {label} <b>{company.name}</b>
      </AYLink>
    );
  };

  const renderBrokers = () => {
    if (hideBrokers) return null;

    if (!tenantBrokerCompanyToRender && !landlordBrokerCompanyToRender)
      return null;
    const brokersItems = [
      tenantBrokerCompanyToRender && (
        <BrokerLink
          company={tenantBrokerCompanyToRender}
          label={t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.trBroker`)}
        />
      ),
      landlordBrokerCompanyToRender && (
        <BrokerLink
          company={landlordBrokerCompanyToRender}
          label={t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.alBroker`)}
        />
      ),
    ];
    return (
      <>
        <Row>
          <Col wrapperClassName={styles['brokers-line']}>
            <ListWithSeparator
              items={brokersItems}
              separator={<span>{DOT_LG}</span>}
            />
          </Col>
        </Row>
      </>
    );
  };

  const renderNotes = () => {
    if (!lease.comments) return null;
    return (
      <Row>
        <Col>
          <CompsComments
            className={styles.notes}
            comments={lease.comments}
            hideNote={isCarousel}
          />
        </Col>
      </Row>
    );
  };

  const renderCreatedByUser = () => {
    if (!lease.createdUser) return null;
    return (
      <Row>
        <Col>
          <Owner
            createdUser={lease.createdUser}
            createdAt={lease.createdAt!}
            updatedUser={lease.updatedUser!}
            updatedAt={lease.updatedAt}
            colored
            lineBreak={type === TypeOfCard.HOME || isCarousel}
            ignoreSameUserEdit
            wrapperClassName={styles['owner-container']}
            smallText={isCarousel}
          />
        </Col>
      </Row>
    );
  };

  const renderBrokersAndNotes = () => {
    const content = (
      <>
        {renderBrokers()}
        {renderNotes()}
      </>
    );

    if (!isCarousel) {
      return content;
    }

    if (isCarousel && isSeeMoreClicked) {
      return content;
    }

    return null;
  };

  return (
    <>
      <CardContainer
        wrapperClassName={classNames(className, {
          [styles.share]: type === TypeOfCard.SHARE,
        })}
      >
        <CardHeader
          property={property}
          activity={activity}
          lease={lease}
          refetchActivities={refetchScoopList!}
          type={type}
          hideMarketLine={hideMarketLine}
          hideBuildingAttributes={hideBuildingAttributes}
          hideBuildingInfo={hideBuildingInfo}
          tagLabel={t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.signedLease`)}
        />
        <div className={styles.content}>
          {renderSeparator()}
          {renderTenantCompany()}
          {renderTags()}
          {renderBrokersAndNotes()}
          {!hideCreatedUpdate && (
            <>
              {!isCarousel && renderSeparator()}
              {renderCreatedByUser()}
            </>
          )}
        </div>
      </CardContainer>
    </>
  );
};

export default LeaseCard;
