import { translateText } from '../utils/i18n';
import { I18N_PLATFORM_COMMON_WORD_PATH } from './i18n';
import { LeaseBaseRentTime } from './leases';

export enum TimeMeasurement {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
}

export const TIME_TOGGLE_OPTIONS = [
  {
    value: LeaseBaseRentTime.Monthly,
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.monthly`),
  },
  {
    value: LeaseBaseRentTime.Annual,
    text: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.annual`),
  },
];
