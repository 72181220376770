import { IUser } from 'interfaces/IUser';
import { useQuery } from '@apollo/client';
import { MARKETS_QUERY } from 'graphql/markets';
import { IUserInput } from 'interfaces/inputs/IUserInput';
import { IMarket } from 'interfaces/IMarket';
import { useMemo } from 'react';
import { SortDirections } from 'constants/sortDirections';

export const useUserMarkets = (
  user: IUser | Partial<IUserInput>,
  includePropertiesCount?: boolean,
  onCompleteCallback?: (markets: IMarket[]) => void,
): {
  userMarkets: IMarket[];
  isLoading: boolean;
} => {
  const userMarkets = user?.preferences?.marketIds || [];

  const { data, loading } = useQuery<{ markets: IMarket[] }>(MARKETS_QUERY, {
    variables: {
      search: {
        ids: userMarkets,
        order: {
          field: 'name',
          direction: SortDirections.asc,
        },
      },
      includePropertiesCount,
    },
    fetchPolicy: 'cache-first',
    skip: userMarkets?.length === 0,
    onCompleted: data => {
      onCompleteCallback?.(data.markets);
    },
  });

  return {
    userMarkets: useMemo(() => data?.markets || [], [data]),
    isLoading: loading,
  };
};
