import React, { useEffect, useState } from 'react';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';

import Table from './Table';
import { usePagination } from './usePagination';
import LoadingMessage from '../LoadingMessage';
import NotificationMessage from '../NotificationMessage';
import { TableViewType } from './types';
import {
  FindCompsActions,
  useFindCompsData,
} from '../../contexts/FindCompsContext';
import { getCountValue } from '../FindComps/utils';

const TableView = ({
  columns,
  hasEditButton,
  id,
  isActive,
  noResultsMessage,
  onChangeOrder,
  onClickEditSearch,
  order,
  hasSelectItems,
  onSelectedIds,
  selectedIds,
  isSelectAllChecked,
  setIsSelectAllChecked,
  scrollClassName,
  hasItemsLimit,
  hiddenColumns,
  uncheckedIds,
  setUncheckedIds,
  onCompleted,
  query,
  getQueryVariables,
  skip,
  typeOfData,
  displayEditCriteriaBtn,
}: TableViewType) => {
  const { dispatch } = useFindCompsData();
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [hasDataChanges, setHasDataChanges] = useState(false);

  const {
    data,
    items,
    loading,
    error,
    isLoadingNextPage,
    refetch,
    setCurrentPage,
  } = usePagination({
    query,
    getQueryVariables,
    skip: skip,
    isActive: isActive,
    typeOfData,
    onError: () => {
      setShowErrorNotification(true);
    },
    onCompleted: (data: any) => {
      if (onCompleted) {
        onCompleted(data);
      }
    },
  });

  const getLoadingNameByType = () => {
    switch (typeOfData) {
      case 'leases':
        return `${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`;
      case 'sales':
        return `${I18N_PLATFORM_COMMON_WORD_PATH}.sale_plural`;
      case 'properties':
        return `${I18N_PLATFORM_COMMON_WORD_PATH}.property_plural`;
      case 'TIMs':
        return `${I18N_PLATFORM_COMMON_WORD_PATH}.tenantInMarketShortPlural`;
    }
  };

  const countName = `${typeOfData.toLowerCase()}Count`;

  useEffect(() => {
    dispatch({
      type: FindCompsActions.set_totals,
      countValue: getCountValue(
        data,
        typeOfData,
        isSelectAllChecked,
        selectedIds,
        uncheckedIds,
      ),
      countField: countName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedIds, isSelectAllChecked, uncheckedIds]);

  useEffect(() => {
    if (hasDataChanges && refetch) {
      refetch();
      setHasDataChanges(false);
    }
  }, [hasDataChanges, refetch]);

  const renderLoading = () => {
    return (
      <LoadingMessage itemBeingLoaded={translateText(getLoadingNameByType())} />
    );
  };

  if (loading && !isLoadingNextPage) {
    return renderLoading();
  }

  return (
    <>
      <Table
        columns={columns}
        data={items}
        hasEditButton={hasEditButton}
        id={id}
        isActive={isActive}
        noResultsMessage={noResultsMessage}
        onChangeOrder={onChangeOrder}
        onClickEditSearch={onClickEditSearch}
        resetPageCount={() => {
          setCurrentPage(1);
        }}
        onChangeData={() => {
          setCurrentPage(1);
          setHasDataChanges(true);
        }}
        displayEditCriteriaBtn={displayEditCriteriaBtn}
        order={order}
        hasSelectItems={hasSelectItems}
        onSelectedIds={onSelectedIds}
        selectedIds={selectedIds}
        isSelectAllChecked={isSelectAllChecked}
        setIsSelectAllChecked={setIsSelectAllChecked}
        scrollClassName={scrollClassName}
        hasItemsLimit={hasItemsLimit}
        hiddenColumns={hiddenColumns}
        uncheckedIds={uncheckedIds}
        setUncheckedIds={setUncheckedIds}
      />
      {isLoadingNextPage && renderLoading()}

      {showErrorNotification && (
        <NotificationMessage
          show={true}
          text={error?.message ?? ''}
          onClose={() => setShowErrorNotification(false)}
        />
      )}
    </>
  );
};

export default TableView;
