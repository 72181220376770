import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { AgencyAssignmentsProps } from 'components/AgencyAssignments/types';
import { Container, Row } from 'components/@codelitt/ay-design-library';
import ComparativeRows from 'components/PropertyProfile/Sections/Comparative/ComparativeRows';
import PaginationControl from 'components/PaginationControl';
import LoadingMessage from 'components/LoadingMessage';
import { IProperty } from 'interfaces/IProperty';
import { SEARCH_PROPERTIES_COMPLETE_QUERY } from 'graphql/property';

import { getComparativeRows } from './utils';
import styles from './GridContainer.module.scss';

const UI_PAGE_SIZE = 5;

const GridContainer: React.FC<AgencyAssignmentsProps> = ({
  propertySet,
  toggleOptions,
}) => {
  const propertiesIds = propertySet.properties?.map(property => property.id!);

  const { data, loading: isLoading } = useQuery(
    SEARCH_PROPERTIES_COMPLETE_QUERY,
    {
      variables: {
        search: {
          filter: {
            ids: propertiesIds,
          },
        },
      },
      skip: !propertiesIds?.length,
    },
  );

  const [selectedProperties, setSelectedProperties] = useState<IProperty[]>();

  const [uiPage, setUiPage] = useState(1);

  useEffect(() => {
    if (data?.properties) {
      const properties = data?.properties.results;
      setSelectedProperties(
        properties.slice(UI_PAGE_SIZE * (uiPage - 1), UI_PAGE_SIZE * uiPage),
      );
    }
  }, [uiPage, data]);

  const goToNextPage = () => {
    setUiPage(currrentPage => currrentPage + 1);
  };

  const goToPrevPage = () => {
    if (uiPage <= 1) return;
    setUiPage(currrentPage => currrentPage - 1);
  };

  const getComparativeProperties = () => {
    return selectedProperties
      ? selectedProperties.slice(1, selectedProperties.length + 1)
      : [];
  };

  if (!isLoading && selectedProperties) {
    return (
      <Container wrapperClassName={styles.container}>
        <Row>
          <ComparativeRows
            completeCompetitiveProperties={data?.properties.results}
            targetProperty={selectedProperties[0]}
            comparativeProperties={getComparativeProperties()}
            rowConfig={getComparativeRows()}
            toggleOptions={toggleOptions}
          />
        </Row>
        <PaginationControl
          className={styles.pagination}
          currentPage={uiPage}
          totalPages={
            Math.ceil(data?.properties.results.length / UI_PAGE_SIZE) || 1
          }
          onClickPrev={goToPrevPage}
          onClickNext={goToNextPage}
        />
      </Container>
    );
  } else {
    return <LoadingMessage />;
  }
};

export default GridContainer;
