import React, { useState } from 'react';

import ProfileSubSection from 'components/ProfileSubSection';
import InfoBoxes from 'components/InfoBoxes';
import MostExpensiveAndLargestCharts from 'components/MostExpensiveAndLargestCharts';
import AskingRentMap from 'components/AskingRentMap';
import AskingRentBlockSize from 'components/AskingRentBlockSize';
import { SwitchOption } from 'components/SwitchOptionButton';
import {
  ASKING_RENT_MAP_TOGGLE_OPTIONS,
  getPinLabel,
} from 'pages/PropertyPage/CompSetProfilePage/Sections/Availabilities/utils';
import { LeaseToggleOptions } from 'components/Leases/LeaseToggles';
import AgencyLeasingHeader from 'components/AgencyAssignments/AgencyLeasingHeader';
import CommonAgencyLeasingControl from 'components/AgencyAssignments/AgencyLeasingHeader/CommonAgencyLeasingControl';
import { Container } from 'components/@codelitt/ay-design-library';
import { useUserSettings } from 'hooks/useUserSettings';
import { LeaseRentType } from 'constants/leases';
import { translateText } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';

import styles from './Availabilities.module.scss';
import { AvailabilitiesProps } from './types';
import {
  buildInfoBoxData,
  getHighestAskingRentStyle,
  ITEMS_TO_DISPLAY_ON_HIGHEST_BLOCKS_CHART,
} from './utils';

const Availabilities = ({
  agencyLeasing,
  propertyIds,
  blockAvailabilities = {},
}: AvailabilitiesProps) => {
  const { preferredTimePeriod } = useUserSettings();

  const [filters, setFilters] = useState<LeaseToggleOptions>({
    rentType: LeaseRentType.FS,
    timeMeasurement: preferredTimePeriod.value,
  });
  const [mapToggleOption, setMapToggleOption] = useState<SwitchOption>(
    ASKING_RENT_MAP_TOGGLE_OPTIONS[0],
  );

  return (
    <>
      <Container>
        <AgencyLeasingHeader
          breadcrumbLevels={[
            { content: agencyLeasing.name },
            {
              content: translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.availability_plural`,
              ),
            },
          ]}
          actionContent={
            <CommonAgencyLeasingControl
              showMoreTrendsButton
              showExcelDownloadButton
              toggleOptions={filters}
              onChangeToggleOptions={setFilters}
              agencyLeasing={agencyLeasing}
              propertyIds={propertyIds}
            />
          }
        />
      </Container>
      <ProfileSubSection isSingleSection>
        <Container wrapperClassName={styles.container}>
          <>
            <InfoBoxes
              textAlign="center"
              data={buildInfoBoxData(blockAvailabilities || null, filters)}
            />
            <AskingRentMap
              data={blockAvailabilities?.availabilitiesByProperties}
              filters={filters}
              getPinLabel={getPinLabel}
              toggleOptions={ASKING_RENT_MAP_TOGGLE_OPTIONS}
              mapToggleOption={mapToggleOption}
              setMapToggleOption={setMapToggleOption}
              sectionTitle={translateText(
                `${I18N_AVANT_PROPERTY_COMP_SET_LABEL_PATH}.availabilities.askingRent.title`,
              )}
            />
            <AskingRentBlockSize
              filters={filters}
              chartBlockSizeData={blockAvailabilities.chartsBlockSize}
              chartAskingRentData={blockAvailabilities.chartsAskingRent}
            />
            <MostExpensiveAndLargestCharts
              leaseToggles={filters}
              data={blockAvailabilities.availabilitiesByProperties}
              compSet={agencyLeasing}
              mostExpensiveChartStyle={getHighestAskingRentStyle()}
              largestChartStyle={getHighestAskingRentStyle(true)}
              itemsLimit={ITEMS_TO_DISPLAY_ON_HIGHEST_BLOCKS_CHART}
            />
          </>
        </Container>
      </ProfileSubSection>
    </>
  );
};

export { Availabilities };
