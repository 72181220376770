import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { REGEX_INTEGERS } from 'constants/regex';
import { authContext } from 'contexts/AuthContext';
import { LanguageEnum } from 'constants/languages';
import {
  ICustomValidation,
  MONTH_MAX_LENGTH,
  DAY_MAX_LENGTH,
  YEAR_MAX_LENGTH,
  SEPARATOR,
} from 'components/Inputs/DateInput/constants';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { isValidDate } from 'utils/date';
import { translateText } from 'utils/i18n';

import styles from './DateInput.module.scss';

export interface Props {
  id: string;
  value: string | null | undefined;
  onChange?: (value: string | null) => void;
  isDisabled?: boolean;
  disableValidation?: boolean;
  onBlur?: (value: string) => void;
  customValidation?: ICustomValidation;
  wrapperClassName?: string;
  errorClassName?: string;
}

export enum INPUT_FIELD {
  DAY,
  MONTH,
  YEAR,
}

const defaultValidation = {
  isValid: true,
  message: '',
};

const INVALID_DATE_MESSAGE = translateText(
  `${I18N_PLATFORM_COMMON_WORD_PATH}.invalidDate`,
);

const invalidDate = {
  isValid: false,
  message: INVALID_DATE_MESSAGE,
};

const DateInput: React.FC<Props> = props => {
  const {
    id,
    value,
    onChange,
    isDisabled,
    disableValidation,
    onBlur,
    customValidation,
    wrapperClassName,
    errorClassName = '',
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [validation, setValidation] = useState<ICustomValidation>(
    defaultValidation,
  );

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const { user } = useContext(authContext);
  const nonAmericanUser = user.language === LanguageEnum.EN_GB;
  const formattedValue = `${year}-${month}-${day}`;

  const defineValidation = () => {
    if (disableValidation) return defaultValidation;
    if (!isValidDate(value ?? '')) return invalidDate;

    const invalidCustomValidation =
      typeof customValidation === 'object' && !customValidation.isValid;

    return invalidCustomValidation ? customValidation : validation;
  };

  const isInvalid = !defineValidation().isValid;
  const errorMessage = defineValidation().message;

  useEffect(() => {
    const dateParts = value?.split(DASH_PLACEHOLDER);
    if (dateParts?.length !== 3) return;

    setDay(dateParts[2]);
    setMonth(dateParts[1]);
    setYear(dateParts[0]);

    const isValid = isValidDate(value ?? '');
    setValidation({
      isValid,
      message: isValid ? '' : INVALID_DATE_MESSAGE,
    });
  }, [value]);

  const onDatePartFocus = () => setIsFocused(true);

  const onDatePartBlur = () => {
    dayRef.current?.blur();
    monthRef.current?.blur();
    yearRef.current?.blur();

    if (!onBlur) return;

    onBlur?.(formattedValue);
    setIsFocused(false);
  };

  const updateDateComponent = (field: INPUT_FIELD, value: string) => {
    const fieldMapper = {
      [INPUT_FIELD.DAY]: { setter: setDay, value: day },
      [INPUT_FIELD.MONTH]: { setter: setMonth, value: month },
      [INPUT_FIELD.YEAR]: { setter: setYear, value: year },
    };
    const { setter } = fieldMapper[field];
    setter(value);
  };

  const handleFocusShift = (field: INPUT_FIELD, value: string) => {
    const maxLength = 2;

    if (value.length === maxLength) {
      switch (field) {
        case INPUT_FIELD.MONTH:
          return nonAmericanUser
            ? yearRef.current?.focus()
            : dayRef.current?.focus();
        case INPUT_FIELD.DAY:
          return nonAmericanUser
            ? monthRef.current?.focus()
            : yearRef.current?.focus();

        default:
          return;
      }
    } else if (value.length === 0) {
      switch (field) {
        case INPUT_FIELD.DAY:
          return !nonAmericanUser && monthRef.current?.focus();

        case INPUT_FIELD.MONTH:
          return nonAmericanUser && dayRef.current?.focus();

        case INPUT_FIELD.YEAR:
          return nonAmericanUser
            ? monthRef.current?.focus()
            : dayRef.current?.focus();

        default:
          return;
      }
    }
  };

  const validateDate = (formattedValue: string) => {
    const isValid = isValidDate(formattedValue);
    setValidation({
      isValid,
      message: isValid ? '' : INVALID_DATE_MESSAGE,
    });
  };

  const handleDateChange = (formattedValue: string) => {
    const nullableDate = formattedValue === '--';
    onChange?.(nullableDate ? null : formattedValue);
  };

  const getUpdatedFormattedValue = (
    field: INPUT_FIELD,
    year: string,
    month: string,
    day: string,
    value: string,
  ) => {
    return {
      [INPUT_FIELD.DAY]: `${year}-${month}-${value}`,
      [INPUT_FIELD.MONTH]: `${year}-${value}-${day}`,
      [INPUT_FIELD.YEAR]: `${value}-${month}-${day}`,
    }[field];
  };

  const handleInputChange = (field: INPUT_FIELD) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;

    // 1: Validate input
    if (!REGEX_INTEGERS.test(value)) return;
    // 2: Update the date component based on the field
    updateDateComponent(field, value);
    // 3: Generate the updated formatted date value
    const updatedFormattedValue = getUpdatedFormattedValue(
      field,
      year,
      month,
      day,
      value,
    );
    // 4: Validate the updated date value
    validateDate(updatedFormattedValue);
    // 5: Handle change event and nullable date
    handleDateChange(updatedFormattedValue);
    // // 6. Shift focus if maximum length is reached or if value is empty
    handleFocusShift(field, value);
  };

  const renderInput = (
    idSuffix: string,
    value: string,
    ref: React.RefObject<HTMLInputElement>,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    maxLength: number,
    placeholder: string,
  ) => (
    <input
      className={classnames(`date-input-${idSuffix}`, styles[idSuffix])}
      onBlur={onDatePartBlur}
      maxLength={maxLength}
      onChange={onChange}
      onFocus={onDatePartFocus}
      placeholder={isDisabled ? DASH_PLACEHOLDER : placeholder}
      ref={ref}
      type="text"
      value={value}
      disabled={isDisabled}
      id={`${id}-${idSuffix}`}
      data-testid={`${id}-${idSuffix}`}
    />
  );

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles['date-input'], wrapperClassName, {
          [styles['focus']]: isFocused,
          [styles['error']]: isInvalid,
          [errorClassName]: errorClassName && isInvalid,
          [styles.disabled]: isDisabled,
        })}
      >
        {nonAmericanUser
          ? renderInput(
              'day',
              day,
              dayRef,
              handleInputChange(INPUT_FIELD.DAY),
              DAY_MAX_LENGTH,
              'DD',
            )
          : renderInput(
              'month',
              month,
              monthRef,
              handleInputChange(INPUT_FIELD.MONTH),
              MONTH_MAX_LENGTH,
              'MM',
            )}
        <span className={styles.separator}>{SEPARATOR}</span>
        {nonAmericanUser
          ? renderInput(
              'month',
              month,
              monthRef,
              handleInputChange(INPUT_FIELD.MONTH),
              MONTH_MAX_LENGTH,
              'MM',
            )
          : renderInput(
              'day',
              day,
              dayRef,
              handleInputChange(INPUT_FIELD.DAY),
              DAY_MAX_LENGTH,
              'DD',
            )}
        <span className={styles.separator}>{SEPARATOR}</span>
        {renderInput(
          'year',
          year,
          yearRef,
          handleInputChange(INPUT_FIELD.YEAR),
          YEAR_MAX_LENGTH,
          'YYYY',
        )}
      </div>
      {isInvalid && <p className={styles['error-message']}>{errorMessage}</p>}
    </div>
  );
};

export default DateInput;
