import { buildAvantCitiesUrl } from 'utils/avantCities/urlBuilder';

const avantCitiesRoutes = {
  root: () => window._env_.AVANT_CITIES_URL,

  property: (propertyId: number) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      focusProperty: { id: propertyId },
    }),
  properties: (
    propertyIds: number[],
    marketId?: number,
    propertyType?: string,
  ) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      propertyIds,
      marketId,
      propertyType,
    }),

  market: (marketId: number, propertyType?: string) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      marketId,
      propertyType,
    }),

  compSet: (compSet: number[], propertyId?: number) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      focusProperty: { id: propertyId },
      compSet,
    }),

  agencyAssignments: (agencyAssignmentsCompsetId?: number) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      agencyAssignmentsCompsetId,
    }),

  saleSet: (sales: number[], propertyId?: number) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      focusProperty: { id: propertyId },
      saleSet: sales,
    }),

  leaseSet: (leases: number[], propertyId?: number) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      focusProperty: { id: propertyId },
      leaseSet: leases,
    }),

  compSetId: (compSetId: number) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      compSetId,
    }),

  userPreferences: (propertyType?: string, marketId?: number) =>
    buildAvantCitiesUrl(window._env_.AVANT_CITIES_URL, {
      marketId,
      propertyType,
    }),

  mapMaker: () => `${window._env_.AVANT_CITIES_URL}/mapmaker`,
  marketExplorer: () => `${window._env_.AVANT_CITIES_URL}/explore`,
  marketSelection: () => `${window._env_.AVANT_CITIES_URL}/marketselection`,
  guide: () => `${window._env_.AVANT_CITIES_URL}/guide`,
  projects: () => `${window._env_.AVANT_CITIES_URL}/projects`,
  siteSelection: () => `${window._env_.AVANT_CITIES_URL}/siteselection`,
  parcelFinder: () => `${window._env_.AVANT_CITIES_URL}/parcelfinder`,
  panorama: () => `${window._env_.AVANT_CITIES_URL}/panorama`,
  healthcare: () =>
    `${window._env_.AVANT_CITIES_URL}/locationintelligence/healthcare`,
  office: () => `${window._env_.AVANT_CITIES_URL}/locationintelligence/office`,
};

export default avantCitiesRoutes;
