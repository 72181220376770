import React from 'react';
import classNames from 'classnames';

import ModalWrapper from 'components/ModalWrapper';
import { CardLink } from 'components/CardLink';
import { getCoverImageUrl } from 'components/ModalMedia/utils';
import { PropertyImageSizes } from 'constants/propertyImageSizes';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { IProperty } from 'interfaces/IProperty';
import locations from 'routes';
import { getPropertyAddress } from 'utils/formatters/property';
import { formatStringWithDot } from 'utils/formatters/string';

import styles from './ModalPropertiesLinks.module.scss';

interface Props {
  isVisible: boolean;
  properties: IProperty[];
  onClose: () => void;
}

const ModalPropertiesLinks: React.FC<Props> = ({
  isVisible,
  properties,
  onClose,
}) => {
  if (!isVisible) return null;

  return (
    <ModalWrapper
      onCancel={onClose}
      wrapperClassName={classNames(
        styles['modal-container'],
        styles['links-container'],
      )}
      header="Properties"
      withCloseButton
      noPadding
      large
    >
      <section className={styles.content}>
        {properties.map(p => (
          <CardLink
            key={p.id}
            imageSrc={getCoverImageUrl(p, PropertyImageSizes.l)}
            link={locations.showProperty(p?.id!)}
            title={getPropertyAddress(p)!}
            subtitle={p.name || DASH_PLACEHOLDER}
            tertiaryTitle={formatStringWithDot([
              p.market?.name,
              p.submarket?.name,
              p.micromarket?.name,
            ])}
          />
        ))}
      </section>
    </ModalWrapper>
  );
};

export default ModalPropertiesLinks;
