import { gql } from '@apollo/client';

export const WHERE_AM_I = gql`
  query whereAmI(
    $latitude: Float!
    $longitude: Float!
    $geoHierarchyLevels: [String]
  ) {
    whereAmI(
      search: {
        latitude: $latitude
        longitude: $longitude
        geoHierarchyLevels: $geoHierarchyLevels
      }
    ) {
      countryCode
      geoHierarchy
      geoHierarchyLevel
      geoHierarchyLevelName
      geoId
      geometryAreaSqm
      geoName
      locationType
    }
  }
`;

export const TRAVEL_TIME = gql`
  query traveltime(
    $lat: Float!
    $lng: Float!
    $travel_time: Int
    $departure_time: String
    $transportation: TransportationType
  ) {
    traveltime(
      search: {
        lat: $lat
        lng: $lng
        travel_time: $travel_time
        departure_time: $departure_time
        transportation: $transportation
      }
    ) {
      shapes {
        shell {
          lat
          lng
        }
        holes {
          lat
          lng
        }
      }
      geoJSON
    }
  }
`;

export const BOUNDARY_ATTRIBUTE_BY_POINT_INPUT = gql`
  query boundaryAttributeByPoint(
    $geohierarchyLevel: String!
    $haversine: BoundaryCoordinateAndDistance
    $travelTime: TraveltimeInput
    $kpiNames: [String]!
  ) {
    boundaryAttributeByPoint(
      search: {
        geohierarchyLevel: $geohierarchyLevel
        haversine: $haversine
        travelTime: $travelTime
        kpiNames: $kpiNames
      }
    ) {
      kpis {
        kpiName
        kpiValueSum
        kpiMean
        kpiCount
        kpiWtdAvg
      }
    }
  }
`;

export const BOUNDARY_ATTRIBUTE_METADATA = gql`
  query boundaryAttributeMetadata($search: BoundaryAttributeMetadataInput!) {
    boundaryAttributeMetadata(search: $search) {
      kpiName
      productName
      description
      sortOrder
      divisionCategory
      aggFormula
      denominator
      aggMethod
    }
  }
`;
