import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { IProperty } from 'interfaces/IProperty';
import { ICompany } from 'interfaces/ICompany';
import { ILeaseToggles } from 'interfaces/ILeaseToggles';
import { IdName } from 'interfaces/IdName';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import {
  getCompsGraphqlQuery,
  getCompsPopupActionLocation,
  getCompsPopupTagColor,
  getCompsPopupTitle,
  getCompsVariables,
  getGraphDimensions,
  getLeasesQueryParams,
  getMktAvgQueryParams,
  getRentTypeFromMostRecentLease,
  generateChartLegends,
} from 'components/Graphs/RecentLeases/utils';
import { I18N_LEASE_LABEL_PATH } from 'components/Leases/constants';
import CompsPopup from 'components/CompsPopup';
import SpinnerLoader from 'components/SpinnerLoader';
import { PROFILE_TYPES } from 'constants/profileTypes';
import { colors } from 'constants/colors';
import { FindCompTabs } from 'constants/FindCompTabs';
import { I18N_AVANT_PROPERTY_CHART_LABEL_PATH } from 'constants/i18n';
import { authContext } from 'contexts/AuthContext';
import { LEASES_MKT_RENT_AVG_QUERY, GRAPH_LEASES_QUERY } from 'graphql/leases';
import { useUserMarkets } from 'hooks/useUserMarkets';
import { useUserSettings } from 'hooks/useUserSettings';
import locations from 'routes';
import { FindCompsSearchCriteriaService } from 'services/FindCompsSearchCriteriaService';
import { logError } from 'utils/error';
import {
  getCurrencySymbol,
  getListOfCurrencySymbols,
} from 'utils/formatters/currency';
import { convertIMarketIntoIdName } from 'utils/markets';
import { getPredominantMeasurementSystem } from 'utils/unitsOfMeasurement/graphs';
import { translateText } from 'utils/i18n';
import { parseGraphData } from 'utils/historicalLeasing';

import {
  IMktAverageResponse,
  IDataPoint,
  IGraphLeasesResponse,
} from './interfaces';
import {
  prepareDataPoints,
  parseLeasesItemsToDataPoints,
  getValidMarketAverages,
  getValidLeases,
  parseMarketDataToBubbleDataPoints,
  parseLeaseDataToBubbleDataPoints,
} from './lineGraphData';
import VerticalBubbleChart from '../VerticalBubbleChart';
import GraphContainer from '../GraphContainer';
import { RecentLeasesIds } from './graphIds';
import styles from './RecentLeases.module.scss';

const I18N_RECENT_LEASES_CHART_LABEL_PATH = `${I18N_AVANT_PROPERTY_CHART_LABEL_PATH}.recentLeases`;

interface Props {
  activeColor?: string;
  activePropertySet?: IPropertySet | null;
  primaryColor?: string;
  borderColor?: string;
  disableColor?: string;
  disableBorderColor?: string;
  company?: ICompany;
  graphId: RecentLeasesIds;
  hasDataChanges?: boolean;
  isSmall?: boolean;
  marketsFilter?: IdName[];
  onChangeData?: () => void;
  property?: IProperty;
  rentType: string;
  profileType?: PROFILE_TYPES;
  options: ILeaseToggles;
  setOptions: (newOptions: ILeaseToggles) => void;
  showComponent?: (value: boolean) => void;
  timeMeasurementType: string;
  isDefaultFilter?: boolean;
  yHeight?: number;
  xAxisTranslateX?: number;
  xAxisTranslateY?: number;
  dimensions?: ReturnType<typeof getGraphDimensions>;
  className?: string;
}

const RecentLeasesGraph: React.FC<Props> = props => {
  const {
    activeColor = colors.primaryColor400,
    borderColor = colors.primaryColor400,
    disableColor = colors.primaryColor700,
    disableBorderColor = colors.primaryColor600,
    activePropertySet,
    company,
    graphId,
    hasDataChanges,
    isSmall,
    marketsFilter,
    onChangeData,
    primaryColor = colors.primaryColor500,
    property,
    rentType,
    options,
    setOptions,
    showComponent,
    timeMeasurementType,
    profileType = PROFILE_TYPES.PROPERTY,
    isDefaultFilter,
    yHeight = 11.6,
    xAxisTranslateX,
    xAxisTranslateY,
    dimensions = getGraphDimensions(!!isSmall),
    className,
  } = props;

  const { user } = useContext(authContext);
  const { userMarkets } = useUserMarkets(user);
  const history = useHistory();
  const { preferredTimePeriod } = useUserSettings();

  const [
    activeLeaseDataPoint,
    setActiveLeaseDataPoint,
  ] = useState<IDataPoint | null>(null);

  const [
    leaseMarketDataPoint,
    setLeaseMarketDataPoint,
  ] = useState<IDataPoint | null>();

  const [isCompsPopupVisible, setIsCompsPopupVisible] = useState(false);

  const { skip: marketAvgSkip, input: marketAvgInput } = getMktAvgQueryParams({
    activePropertySet,
    property,
  });

  const {
    loading: marketAvgLoading,
    data: marketAvgData,
    refetch: marketAvgRefetch,
    error: marketAvgError,
  } = useQuery<{
    leasesMarketRentAverageNew: IMktAverageResponse;
  }>(LEASES_MKT_RENT_AVG_QUERY, {
    variables: { input: marketAvgInput },
    skip: marketAvgSkip,
  });

  const { skip: leasesSkip, input: leasesInput } = getLeasesQueryParams({
    graphId,
    property,
    company,
    marketsFilter,
  });

  const {
    loading: leasesLoading,
    data: leasesData,
    refetch: leasesRefetch,
    error: leasesError,
  } = useQuery<{
    graphLeases: IGraphLeasesResponse;
  }>(GRAPH_LEASES_QUERY, {
    variables: { input: leasesInput },
    skip: leasesSkip,
    onCompleted: data => {
      const items = data?.graphLeases?.items || [];
      const mostRecentLease = items?.[0];
      setOptions({
        timeMeasurement: preferredTimePeriod.value,
        rentType: getRentTypeFromMostRecentLease(mostRecentLease),
      });
    },
  });

  const isLoading = marketAvgLoading || leasesLoading;
  const hasError = leasesError || marketAvgError;
  const leaseItems = leasesData?.graphLeases?.items || [];

  useEffect(() => {
    if (hasDataChanges) {
      leasesRefetch?.();
      marketAvgRefetch?.();
    }
    // eslint-disable-next-line
  }, [hasDataChanges]);

  useEffect(() => {
    if (activeLeaseDataPoint || leaseMarketDataPoint) {
      setIsCompsPopupVisible(true);
    }
  }, [activeLeaseDataPoint, leaseMarketDataPoint]);

  if (isLoading) {
    return <SpinnerLoader />;
  }

  if (hasError) {
    logError('RecentLeasesGraph', {
      leasesError,
      marketAvgError,
      leasesInput,
    });

    showComponent?.(false);
    return null;
  }

  const averages = getValidMarketAverages(
    marketAvgData?.leasesMarketRentAverageNew?.averages || [],
    rentType,
  );
  const leases = getValidLeases(leaseItems, rentType);

  const leasesMeasurementSystems = leases.map(l => l.measurementSystem);
  const leasesCurrencyCodes = leases.map(l => l.currencyCode!);

  const currencySymbol =
    property?.propertyCountry?.currencyCode || property?.currencyCode
      ? getCurrencySymbol(
          property?.propertyCountry?.currencyCode || property?.currencyCode,
        )
      : getListOfCurrencySymbols(leasesCurrencyCodes);

  const measurementSystem =
    property?.propertyCountry?.code ||
    property?.measurementSystem ||
    getPredominantMeasurementSystem(leasesMeasurementSystems);

  const graphData = parseGraphData(
    property,
    graphId,
    {
      historicalAverage: marketAvgData?.leasesMarketRentAverageNew,
      historicalLeases: leasesData?.graphLeases,
    },
    options,
    undefined,
    true,
  );
  const graphDataPoints = prepareDataPoints({
    graphId,
    averages,
    leases,
    timeMeasurementType,
    rentType,
    isDefaultFilter,
    isQuarterly: true,
    currencyCode:
      property?.propertyCountry?.currencyCode || property?.currencyCode,
  });

  const marketBubbleDataPoints = parseMarketDataToBubbleDataPoints(
    graphDataPoints,
    rentType,
    undefined,
    true,
  );

  const graphDataLeases = parseLeasesItemsToDataPoints({
    graphId,
    leases,
    rentType,
    measurementSystem,
    timeMeasurementType,
  });

  const hasEmptyLeasesList =
    !graphDataPoints?.length || (company?.id && !graphDataLeases?.length);

  showComponent?.(!hasEmptyLeasesList);

  if (hasEmptyLeasesList) {
    return null;
  }

  const leaseBubbleDataPoints = parseLeaseDataToBubbleDataPoints(
    graphDataLeases,
    rentType,
    undefined,
    primaryColor,
    activeColor,
    borderColor,
    disableColor,
    disableBorderColor,
  );

  const getCompsAction = () => {
    if (activeLeaseDataPoint) {
      return () => {
        const leaseTenantCompany = leases.find(
          l => l.id === activeLeaseDataPoint.id,
        )?.tenantCompany;

        history.push(
          getCompsPopupActionLocation({
            markets: marketsFilter || userMarkets.map(convertIMarketIntoIdName),
            property,
            company,
            leaseTenantCompany,
          }),
        );
      };
    } else if (leaseMarketDataPoint) {
      // go to find comps
      return () =>
        history.push(
          locations.findComps({
            resultActiveTab: FindCompTabs.leases,
            urlParams: FindCompsSearchCriteriaService.buildPropertyFilters(
              property!,
            ),
          }),
        );
    }
  };

  const headerTitle = property
    ? translateText(`${I18N_LEASE_LABEL_PATH}.recentLeases`)
    : translateText(
        `${I18N_RECENT_LEASES_CHART_LABEL_PATH}.companyRecentLeasesTitle`,
      );

  return (
    <GraphContainer
      wrapperClassName={classnames(
        styles['recent-leases-graph-container'],
        className,
        {
          [styles['small']]: isSmall,
        },
      )}
    >
      <VerticalBubbleChart
        className={styles.chart}
        containerClassName={styles['chart-container']}
        hasHorizontalLines
        useYPadding={false}
        yHeight={yHeight}
        title={headerTitle}
        data={{
          ...graphData,
          bubbleItems: leaseBubbleDataPoints,
          marketItems: marketBubbleDataPoints,
        }}
        graphId={graphId}
        graphDimensions={dimensions}
        xAxisTranslateX={xAxisTranslateX}
        xAxisTranslateY={xAxisTranslateY}
        legends={generateChartLegends(profileType)}
        onClickLeaseBubble={bubbleItem => setActiveLeaseDataPoint(bubbleItem)}
        onClickAvgLinePoint={marketBubble =>
          setLeaseMarketDataPoint(marketBubble)
        }
      />
      {isCompsPopupVisible && (
        <CompsPopup
          action={getCompsAction()}
          actionLabel={translateText(
            `${I18N_LEASE_LABEL_PATH}.allRecentLeases`,
          )}
          graphqlVariables={getCompsVariables(
            property,
            activeLeaseDataPoint,
            leaseMarketDataPoint,
            undefined,
            true,
          )}
          tagBackgroundColor={getCompsPopupTagColor(
            graphId,
            leaseMarketDataPoint,
          )}
          onClose={() => {
            setIsCompsPopupVisible(false);
            setActiveLeaseDataPoint(null);
            setLeaseMarketDataPoint(null);
          }}
          showPropertyData={!!leaseMarketDataPoint || !!company?.id}
          title={getCompsPopupTitle({
            activeLeaseDataPoint,
            currencySymbol,
            leaseMarketDataPoint,
            rentType,
          })}
          useLeaseTitle
          graphqlQuery={
            getCompsGraphqlQuery(activeLeaseDataPoint, leaseMarketDataPoint)!
          }
          onChangeData={onChangeData}
          hasDataChanges={hasDataChanges}
        />
      )}
    </GraphContainer>
  );
};

export default RecentLeasesGraph;
