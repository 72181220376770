export enum ExportTypes {
  leases = 'leases',
  sales = 'sales',
  activities = 'activities',
  properties = 'properties',
  tims = 'tims',
  audits = 'audits',
  jsreport = 'jsreport',
  compSet = 'compSet',
}
